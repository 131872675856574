import * as React from 'react';

import Box from '@mui/material/Box';
import { Divider, Fade, Grid, Slide } from '@mui/material';

import { Link } from "react-router-dom";

import MasonryImageList from '../../components/mansonryImageList/mansonryImageList';
import TimelineSection from 'components/timelineSection/TimelineSection';
import logo from '../../assets/images/SmartestHelmetConcept/logo.png'
import wireframe from '../../assets/images/SmartestHelmetConcept/wireframe.gif'

import SmartestHelmetBig1 from '../../assets/images/SmartestHelmetConcept/1.png'
import SmartestHelmetBig2 from '../../assets/images/SmartestHelmetConcept/2.png'
import SmartestHelmetBig3 from '../../assets/images/SmartestHelmetConcept/3.png'

import SmartestHelmetSmall1 from '../../assets/images/SmartestHelmetConcept/5.png'
import SmartestHelmetSmall2 from '../../assets/images/SmartestHelmetConcept/21.png'

import SmartestHelmetSmall3 from '../../assets/images/SmartestHelmetConcept/9.png'
import SmartestHelmetSmall4 from '../../assets/images/SmartestHelmetConcept/19.png'
import SmartestHelmetSmall5 from '../../assets/images/SmartestHelmetConcept/15.png'
// import SmartestHelmetSmall6 from '../../assets/images/SmartestHelmetConcept/28.png'
// import SmartestHelmetSmall7 from '../../assets/images/SmartestHelmetConcept/30.png'
import SmartestHelmetSmall8 from '../../assets/images/SmartestHelmetConcept/20.png'
import SmartestHelmetSmall10 from '../../assets/images/SmartestHelmetConcept/23.png'

import SmartestHelmetCamera from '../../assets/images/SmartestHelmetConcept/18.png'
import SmartestHelmetLed from '../../assets/images/SmartestHelmetConcept/6.png'

import ActionCameraRecording from '../../assets/images/FeatureIcons/ActionCameraRecording.png'
import ActiveProtection from '../../assets/images/FeatureIcons/ActiveProtection.png'
import ActiveProtection2 from '../../assets/images/FeatureIcons/ActiveProtection2.png'
import ComwithOtherUsers from '../../assets/images/FeatureIcons/ComwithOtherUsers.png'
import EmergencyCall from '../../assets/images/FeatureIcons/EmergencyCall.png'
import GpsHelmetTracker from '../../assets/images/FeatureIcons/GpsHelmetTracker.png'
import HeadsUpDisplay from '../../assets/images/FeatureIcons/HeadsUpDisplay.png'
import OvertheAirUpdates from '../../assets/images/FeatureIcons/OvertheAirUpdates.png'
import UltraBrightRearLed from '../../assets/images/FeatureIcons/UltraBrightRearLed.png'
import VoiceAssistant from '../../assets/images/FeatureIcons/VoiceAssistant.png'

import vid from '../../assets/images/SmartestHelmetConcept/vid.mp4'

import MKTypography from 'MKcomponents/MKTypography';
import MKBox from 'MKcomponents/MKBox';
import Footer from 'components/footer/footer';
import footerRoutes from 'components/footer/footerRoutes';
import MKButton from 'MKcomponents/MKButton';
import YoutubeEmbed from 'components/youtubeEmbed/youtubeEmbed';
import SlidingImages from 'components/slidingImages/SlidingImages';
import StackCard from 'components/stackCard/StackCard';

const itemData = [
    {
        img: SmartestHelmetSmall1,
        title: 'SmartestHelmetSmall1',
    },
    {
        img: SmartestHelmetSmall2,
        title: 'SmartestHelmetSmall2',

    }
];

const itemData2 = [
    {
        img: SmartestHelmetBig3,
        title: 'SmartestHelmetBig3',
        featured: true,
    },
]

const itemData3 = [

    {
        img: SmartestHelmetBig2,
        title: 'SmartestHelmetBig2',
        featured: true,
    },
]

const SmartestHelmetPage = ({ mobileView }) => {
    const [isVisible2, setVisible2] = React.useState(false);
    const [isVisible3, setVisible3] = React.useState(false);

    const containerRef = React.useRef(null);

    const domRef2 = React.useRef();

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible3(entry.isIntersecting));
        });

        if (domRef2.current) {
            observer.observe(domRef2.current);
            observerRefValue = domRef2.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible2(entry.isIntersecting));
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
            observerRefValue = containerRef.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: "#f3f3f3" }}>
            <Fade in={true} timeout={2000}>
                <Box
                    sx={!mobileView ? {
                        width: '100vw',
                        minHeight: "100vh",
                        backgroundImage: `url(${SmartestHelmetBig1})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } : {
                        width: '100vw',
                        height: "60vh",
                        backgroundImage: `url(${SmartestHelmetBig1})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                </Box>
            </Fade>

            <Fade in={isVisible3} timeout={1000} ref={domRef2}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '100vw',
                        fontSize: '100px',
                        marginRight: '10px',
                        textAlign: 'right'
                    } : {
                        color: '#000',
                        width: '100vw',
                        fontSize: '35px',
                        textAlign: 'right'
                    }}>Smartest Helmet</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '80px',
                        marginTop: '20px',
                        letterSpacing: '10px',
                        fontWeight: 'light',
                        flex: 1
                    } : {
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '30px',
                        marginTop: '20px',
                        letterSpacing: '5px',
                        fontWeight: 'light',
                        flex: 1
                    }}>Active Protection</MKTypography>
                    <MKTypography variant="h6" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        marginBottom: '30px',
                        fontSize: '30px',
                        letterSpacing: '10px',
                        fontWeight: 'light',
                        flex: 1
                    } : {
                        position: 'relative',
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        marginBottom: '30px',
                        fontSize: '20px',
                        letterSpacing: '3px',
                        fontWeight: 'light',
                        flex: 1
                    }}>Danger Prediction & Warning System</MKTypography>
                </Box>
            </Fade>

            <Box ref={containerRef} sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'space-around', paddingTop: '100px', paddingBottom: '100px' }}>
                <Slide direction="up" timeout={2000} in={isVisible2} container={containerRef.current} mountOnEnter unmountOnExit>
                    <MKBox shadow="lg" className="justTesting"
                        sx={!mobileView ? {
                            display: 'flex',
                            minHeight: "50vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: "30vw",
                            position: 'relative',
                            top: '-80px',
                            borderRadius: "30px",
                            '&:hover': {
                                backgroundImage: `url(${SmartestHelmetBig1})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            },
                            '&:hover :nth-child(1)': {
                                display: "none"
                            },
                            '&:hover :nth-child(2)': {
                                display: "none"
                            },
                        } : {
                            display: 'flex',
                            minHeight: "25vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: "30vw",
                            position: 'relative',
                            top: '-80px',
                            borderRadius: "20px",
                            '&:hover': {
                                backgroundImage: `url(${SmartestHelmetBig1})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            },
                            '&:hover :nth-child(1)': {
                                display: "none"
                            },
                            '&:hover :nth-child(2)': {
                                display: "none"
                            },
                        }}
                    >
                        <Box
                            sx={!mobileView ? {
                                minHeight: "10vh",
                                width: "100%",
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            } : {
                                minHeight: "5vh",
                                width: '100%',
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        } : {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '2px',
                            alignSelf: 'center'
                        }}>Heads Up <br /> Display</MKTypography>
                    </MKBox>
                </Slide>
                <Slide direction="up" timeout={3000} in={isVisible2} container={containerRef.current} mountOnEnter unmountOnExit>
                    <MKBox shadow="lg"
                        sx={!mobileView ? {
                            display: 'flex',
                            minHeight: "50vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: "30vw",
                            borderRadius: "30px",
                            '&:hover': {
                                backgroundImage: `url(${SmartestHelmetCamera})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            },
                            '&:hover :nth-child(1)': {
                                display: "none"
                            },
                            '&:hover :nth-child(2)': {
                                display: "none"
                            },
                        } : {
                            display: 'flex',
                            minHeight: "25vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: "30vw",
                            borderRadius: "20px",
                            '&:hover': {
                                backgroundImage: `url(${SmartestHelmetCamera})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            },
                            '&:hover :nth-child(1)': {
                                display: "none"
                            },
                            '&:hover :nth-child(2)': {
                                display: "none"
                            },
                        }}
                    >
                        <Box
                            sx={!mobileView ? {
                                minHeight: "10vh",
                                width: "100%",
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            } : {
                                minHeight: "5vh",
                                width: '100%',
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        } : {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '2px',
                            alignSelf: 'center'
                        }}>Active <br /> Protection</MKTypography>
                    </MKBox>
                </Slide>
                <Slide direction="up" timeout={1000} in={isVisible2} container={containerRef.current} mountOnEnter unmountOnExit>
                    <MKBox shadow="lg"
                        sx={!mobileView ? {
                            display: 'flex',
                            minHeight: "50vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: "30vw",
                            position: 'relative',
                            top: '+80px',
                            borderRadius: "30px",
                            '&:hover': {
                                backgroundImage: `url(${SmartestHelmetLed})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            },
                            '&:hover :nth-child(1)': {
                                display: "none"
                            },
                            '&:hover :nth-child(2)': {
                                display: "none"
                            },
                        } : {
                            display: 'flex',
                            minHeight: "25vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: "30vw",
                            position: 'relative',
                            top: '+80px',
                            borderRadius: "20px",
                            '&:hover': {
                                backgroundImage: `url(${SmartestHelmetLed})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            },
                            '&:hover :nth-child(1)': {
                                display: "none"
                            },
                            '&:hover :nth-child(2)': {
                                display: "none"
                            },
                        }}
                    >
                        <Box
                            sx={!mobileView ? {
                                minHeight: "10vh",
                                width: "100%",
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            } : {
                                minHeight: "5vh",
                                width: '100%',
                                backgroundImage: `url(${logo})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        } : {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '2px',
                            alignSelf: 'center'
                        }}>Ultra <br /> Bright <br /> LED</MKTypography>
                    </MKBox>
                </Slide>
            </Box>

            <Box sx={{
                display: 'flex',
                width: '100vw',
                backgroundColor: "#f3f3f3",
                zIndex: '1',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '90%', marginTop: '10px' }} />
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    position: 'relative',
                    width: '90%',
                    color: '#000',
                    textAlign: 'left',
                    fontSize: '30px',
                    letterSpacing: '5px',
                    fontWeight: '400',
                    marginTop: '10px',
                    marginBottom: '50px'
                } : {
                    position: 'relative',
                    width: '90%',
                    color: '#000',
                    textAlign: 'left',
                    fontSize: '25px',
                    fontWeight: '400',
                    letterSpacing: '2px',
                }}>World's first motorcycle helmet with a danger prediction and warning system that prevents accidents by warning riders of possible dangers.</MKTypography>
            </Box>

            <Box sx={{
                display: 'flex',
                width: '100vw',
                backgroundColor: "#f3f3f3",
                zIndex: '1',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <MasonryImageList itemData={itemData3} />
            </Box>

            <MKTypography mt={6} variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '70vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: '500',
                letterSpacing: '3px',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '500',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>The Smartest Helmet's AI is designed to actively safeguard the rider</MKTypography>

            <MasonryImageList itemData={itemData} />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: '500',
                letterSpacing: '3px',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '500',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Constantly scans 360 degrees and warns the rider about possible dangerous situations, and significantly protects human life by reducing the risk of accidents.</MKTypography>

            <MasonryImageList itemData={itemData2} />

            <SlidingImages
                image1={SmartestHelmetBig1}
                image2={SmartestHelmetBig2}
                image3={SmartestHelmetBig3}
                image4={SmartestHelmetSmall1}
                image5={SmartestHelmetSmall2}
                image6={SmartestHelmetSmall3}
                image7={SmartestHelmetSmall4}
                image8={SmartestHelmetSmall5}
                image9={SmartestHelmetSmall8}
                image10={SmartestHelmetSmall10}
            />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '3px',
                marginTop: '30px',
                marginBottom: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '2px',
                marginTop: '10px',
                marginBottom: '10px'
            }}>Computer vision, deep learning, machine learning technologies, and more work harmoniously to warn the rider to take the required action using visual and auditory warnings before the accident happens.</MKTypography>

            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', width: '100vw', height: '100vh', alignItems: 'center' } :  { display: 'flex', justifyContent: 'center', width: '100vw', height: '50vh', alignItems: 'center' }}>
                <video controls loop mutedn style={{ width: '100%', maxHeight: '100%' }}>
                    <source src={vid} type="video/mp4"></source>
                </video>
            </Box>

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                letterSpacing: '10px',
                fontWeight: 'light',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Features</MKTypography>

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'left',
                fontSize: '20px',
                fontWeight: '400',
                letterSpacing: '3px',
                marginTop: '30px',
                marginBottom: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'left',
                fontSize: '15px',
                fontWeight: '400',
                letterSpacing: '1px',
                marginTop: '20px',
                marginBottom: '10px'
            }}>
                <li>Danger Prediction & Warning System</li>
                <br />
                <li>Communication with Other Users</li>
                <br />
                <li>Heads-Up Display</li>
                <br />
                <li>Ultra-Bright Rear LED</li>
                <br />
                <li>Emergency Call</li>
                <br />
                <li>Action Camera Recording</li>
                <br />
                <li>GPS Helmet Tracker</li>
                <br />
                <li>Voice Asistant</li>
                <br />
                <li>Over the Air Updates</li>
            </MKTypography>

            <SlidingImages
                image1={ActionCameraRecording}
                image2={ActiveProtection}
                image3={ComwithOtherUsers}
                image4={EmergencyCall}
                image5={GpsHelmetTracker}
                image6={HeadsUpDisplay}
                image7={OvertheAirUpdates}
                image8={UltraBrightRearLed}
                image9={VoiceAssistant}
                image10={ActiveProtection2}
            />

            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#f3f3f3', width: '100vw', height: '70vh', alignItems: 'center' } : { display: 'flex', justifyContent: 'center', bgcolor: '#f3f3f3', width: '100vw', height: '50vh', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: "100%",
                        height: '100%',
                        backgroundImage: `url(${wireframe})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            </Box>

            <StackCard mobileView={mobileView} title='Underlying Technology' subtitle='Autonomous Driving Software Developed using Computer Vision, Deep Learning, and Machine Learning' helmetpage />

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '100%', marginTop: '10px' }} />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                letterSpacing: '10px',
                fontWeight: 'light',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Why Smartest Helmet?</MKTypography>

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'left',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '3px',
                marginTop: '30px',
                marginBottom: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'left',
                fontSize: '15px',
                fontWeight: '400',
                letterSpacing: '1px',
                marginTop: '20px',
                marginBottom: '10px'
            }}>
                <li>Motorcycles are the deadliest transportation vehicles by far and unfortunately not a single protective gear for motorcycle riders reduces the likelihood of an accident.</li>
                <br />
                <li>More than 380,000 motorcycle riders die each year, and millions get injured.</li>
                <br />
                <li>Smartest Helmet will actively protect the rider, not just after an accident.</li>
                <br />
                <li>Smartest Helmet will reduce motorcycle accidents through computer vision and AI.</li>
            </MKTypography>

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '100%', marginTop: '10px' }} />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                letterSpacing: '10px',
                fontWeight: 'light',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Timeline</MKTypography>

            <Box sx={!mobileView ? { width: '70vw', marginTop: '30px', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '35px' } :  { width: '100vw', marginTop: '30px', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '35px' }}>
                <TimelineSection mobileView={mobileView} />
            </Box>

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '100%', marginTop: '10px' }} />

            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#f3f3f3', width: '90vw', height: '70vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` } :  { display: 'flex', justifyContent: 'center', bgcolor: '#f3f3f3', width: '90vw', height: '40vh', alignItems: 'center', alignSelf: 'center', margin: '20px', borderRadius: '20px', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}>
                <Grid container sx={{ width: '100%', height: '100%' }}>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetSmall3})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetBig2})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetSmall5})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetSmall8})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MKButton
                            component={Link}
                            to='/wishlist'
                            variant="gradient"
                            color="primary"
                            size={`${!mobileView ? 'large' : 'small'}`}
                            sx={{
                                fontFamily: "'M PLUS Rounded 1c', sans-serif",
                                borderRadius: '30px',
                            }}
                        >
                            Join The Wishlist
                        </MKButton>
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetBig1})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetSmall4})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetSmall10})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            sx={{
                                minWidth: "100%",
                                minHeight: '100%',
                                backgroundImage: `url(${SmartestHelmetSmall1})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                transition: "transform 0.15s ease-in-out",
                                '&:hover': {
                                    transform: "scale3d(1.5, 1.5, 1)",
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '100%', marginTop: '10px' }} />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '80vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                letterSpacing: '10px',
                fontWeight: 'light',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Learn More</MKTypography>

            <Box sx={!mobileView ? {
                display: "flex",
                width: "100%",
                height: "400px",
                margin: '100px',
                justifyContent: 'center',
                alignItems: 'center'
            } : {
                display: "flex",
                flexDirection: 'column',
                width: "90%",
                height: "90vh",
                margin: '50px',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <YoutubeEmbed embedId="emHaQFSUGFM" />
                <YoutubeEmbed embedId="fD5dBIlc0eA" />
                <YoutubeEmbed embedId="7ylfPFWxo1c" />
            </Box>

            <MKBox>
                <Footer content={footerRoutes} />
            </MKBox>

        </Box >
    )
}

export default SmartestHelmetPage