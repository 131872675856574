/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../MKcomponents/MKBox";
import MKTypography from "../../MKcomponents/MKTypography";
import { Box } from "@mui/material";

function Footer({ content }) {
    const { brand, socials, menus, copyright } = content;

    const downloadClickHandler = () => {
        // using Java Script method to get PDF file
        fetch('pitchdeck.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'smartest_pitch_deck.pdf';
                alink.click();
            })
        })
    }

    return (
        <MKBox component="footer" sx={{ bgcolor: '#000', width: '100vw' }}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} sx={{ ml: "auto", mb: 3 }}>
                        <Box
                            sx={{
                                width: '100%',
                                minHeight: "100%",
                                backgroundImage: brand.image,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        >
                        </Box>
                        <MKBox display="flex" alignItems="center" mt={1} sx={{ justifyContent: 'center' }}>
                            {socials.map(({ icon, link }, key) => (
                                <MKTypography
                                    key={link}
                                    component="a"
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="h5"
                                    opacity={0.8}
                                    mr={key === socials.length - 1 ? 0 : 2.5}
                                    sx={{ fontSize: '22px', color: '#fff', transition: '0.5s', '&:hover': { color: '#e8a7dd' } }}
                                >
                                    {icon}
                                </MKTypography>
                            ))}
                        </MKBox>
                    </Grid>
                    {menus.map(({ name: title, items }) => (
                        <Grid key={title} item xs={6} md={4} sx={{ mb: 3 }}>
                            <MKTypography
                                display="block"
                                variant="button"
                                fontWeight="bold"
                                color='white'
                                textTransform="capitalize"
                                mb={1}
                            >
                                {title}
                            </MKTypography>
                            <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                                {items.map(({ name, route, href, download }) => (
                                    <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                                        {href ? (
                                            <MKTypography
                                                component="a"
                                                href={!download ? href : null}
                                                onClick={() => download ? downloadClickHandler() : null}
                                                target="_blank"
                                                rel="noreferrer"
                                                color='white'
                                                variant="button"
                                                fontWeight="regular"
                                                sx={{ color: '#fff', transition: '0.5s', '&:hover': { color: '#e8a7dd', cursor: 'pointer' } }}
                                            >
                                                {name}
                                            </MKTypography>
                                        ) : (
                                            <MKTypography
                                                component={Link}
                                                to={route}
                                                variant="button"
                                                color='white'
                                                fontWeight="regular"
                                                textTransform="capitalize"
                                                sx={{ color: '#fff', transition: '0.5s', '&:hover': { color: '#e8a7dd' } }}
                                            >
                                                {name}
                                            </MKTypography>
                                        )}
                                    </MKBox>
                                ))}
                            </MKBox>
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
                        {copyright}
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

// Typechecking props for the Footer
Footer.propTypes = {
    content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default Footer;
