import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore, addDoc, collection } from "firebase/firestore";
require("firebase/firestore");

const firebaseConfig = {
    apiKey: "AIzaSyCOphYgWTQC02xiK3Sd7WQSNXxYERA0N5Q",
    authDomain: "smartest-9169b.firebaseapp.com",
    projectId: "smartest-9169b",
    storageBucket: "smartest-9169b.appspot.com",
    messagingSenderId: "1031830698280",
    appId: "1:1031830698280:web:39e987b3626d664668b81d",
    measurementId: "G-DN9PJXCHMR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const db = getFirestore(app);

export const joinBuyWishlist = async (firstName, surname, email, selectedCountry, model, price, selectedColorValue, checkboxArr, radioButtonValue) => {
    const docRef = await addDoc(collection(db, "wishlist"), {
        firstName: firstName,
        surname: surname,
        email: email,
        selectedCountry: selectedCountry,
        model: model,
        type: 'Buy', 
        price: price, 
        addOns: checkboxArr,
        color: selectedColorValue,
        buyingModel: radioButtonValue
    });
    return docRef.id
}

export const joinRentWishlist = async (firstName, surname, email, selectedCountry, model, rentPrice, selectedColorValue, checkboxArr, radioButtonValue2) => {
    const docRef = await addDoc(collection(db, "wishlist"), {
        firstName: firstName,
        surname: surname,
        email: email,
        selectedCountry: selectedCountry,
        model: model,
        type: 'Rent', 
        rentPrice: rentPrice, 
        addOns: checkboxArr,
        color: selectedColorValue,
        rentingType: radioButtonValue2
    });
    return docRef.id
}

// joinHelmetWishlist
export const joinHelmetWishlist = async (firstName, surname, email, selectedCountry) => {
    const docRef = await addDoc(collection(db, "smartest_helmet_wishlist"), {
        firstName: firstName,
        surname: surname,
        email: email,
        selectedCountry: selectedCountry,
    });
    return docRef.id
}