import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: '#e42c6c88',
    backdropFilter: `blur(16px)`,
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: 24,
    p: 4,
};


function BasicModal({ open, handleClose }) {
    return (
        <div style={{ width: '80%', marginTop: '20px' }}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ color: '#fff' }}>
                        Thank you for joining our wishlist.
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#fff' }}>
                        We'll let you know as soon as we're ready for orders!
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default BasicModal