import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const MasonryImageList = ({ itemData }) => {
    return (
        <Box mt={6} sx={{ backgroundColor: 'prımary.dark', width: '100vw' }}>
            <ImageList gap={0}>
                {itemData.map((item) => {
                    const cols = item.featured ? 2 : 1;
                    const rows = item.featured ? 2 : 1;

                    return (
                        <ImageListItem key={item.img} cols={cols} rows={rows} >
                            <img
                                src={`${item.img}?w=${248 * rows}&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=${248 * rows}&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </ImageListItem>
                    )
                })}
            </ImageList>
        </Box>
    );
}

export default MasonryImageList;
//sx={{ borderTopRightRadius: `${Math.floor(Math.random() * 20)}px`,borderBottomRightRadius: `${Math.floor(Math.random() * 20)}px`, overflow: 'hidden' }}

// , marginLeft: '10px', marginRight: '10px'
// sx={{ borderWidth: '1px', borderColor: 'white', borderStyle: 'solid' }}
