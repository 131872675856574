import * as React from 'react';

//particle stuff
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
//end of particle stuff

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

import lab2 from '../../assets/images/lab2.jpg'
import lab3 from '../../assets/images/lab3.webp'

import obj1 from '../../assets/images/labPage/1.png'
import obj2 from '../../assets/images/labPage/2.png'
import obj3 from '../../assets/images/labPage/3.jpg'
import obj4 from '../../assets/images/labPage/4.png'

import depth1 from '../../assets/images/labPage/5.png'
import depth2 from '../../assets/images/labPage/6.png'
import depth3 from '../../assets/images/labPage/7.png'
import depth4 from '../../assets/images/labPage/9.png'


// import objectIcon from '../../assets/images/objectIcon.png'
// import distanceIcon from '../../assets/images/distanceIcon.png'
// import laneIcon from '../../assets/images/laneIcon.png'

import org from '../../assets/images/SmartestHelmet/original.gif'
import yolo from '../../assets/images/SmartestHelmet/yolo.gif'

import moment1 from '../../assets/images/SmartestHelmet/SmartestHelmetMoment1.jpg'
import moment2 from '../../assets/images/SmartestHelmet/SmartestHelmetMoment2.jpg'

//import demogif from '../../assets/images/SmartestHelmet/SmartestHelmetDemo.gif'

//import wireframe from '../../assets/images/SmartestHelmet/SmartestHelmetWireframe.gif'

import hiring from '../../assets/images/hiring.webp'

import MKTypography from 'MKcomponents/MKTypography';
import MKBox from 'MKcomponents/MKBox';
import Footer from 'components/footer/footer';
import footerRoutes from 'components/footer/footerRoutes';
import { Divider } from '@mui/material';
import MKButton from 'MKcomponents/MKButton';

import breakpoints from "assets/theme/base/breakpoints";
import YoutubeEmbed from 'components/youtubeEmbed/youtubeEmbed';
import LabImageGrid from 'components/labImageGrid/LabImageGrid';
import StackCard from 'components/stackCard/StackCard';

const LabPage = () => {
    //particle stuff
    const particlesInit = useCallback(async engine => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
    //end of particle stuff

    const [isVisible4, setVisible4] = React.useState(false);
    const domRef2 = React.useRef();

    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    const [mobileView, setMobileView] = React.useState(false);

    React.useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible4(entry.isIntersecting));
        });

        if (domRef2.current) {
            observer.observe(domRef2.current);
            observerRefValue = domRef2.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });

        if (domRef.current) {
            observer.observe(domRef.current);
            observerRefValue = domRef.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);


    const containerRef = React.useRef(null);
    return (
        <Box>
            <Box sx={{ position: 'fixed', width: '100vw', height: '100vh', top: 0 }}>
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#ffffff",
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 1,
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#c0c0c0",
                            },
                            links: {
                                color: "#c0c0c0",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            collisions: {
                                enable: false,
                            },
                            move: {
                                directions: "none",
                                enable: true,
                                outModes: {
                                    default: "out",
                                },
                                random: false,
                                speed: 2,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 120,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 5 },
                            },
                        },
                        detectRetina: true,
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100vh',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        zIndex: 1
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '90vw',
                        fontSize: '100px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: "'M PLUS Rounded 1c', sans-serif",
                    } : {
                        color: '#000',
                        width: '100vw',
                        fontSize: '48px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        fontFamily: "'M PLUS Rounded 1c', sans-serif",
                    }}>Smartest Labs</MKTypography>
                </Box>

                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

                <Box ref={containerRef} sx={{ display: 'flex', width: '100vw', height: '40vh', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                    <Fade in={true} timeout={2000}>
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '80%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '30px',
                            fontWeight: '400',
                        } : {
                            width: '90%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '400',
                        }}>The Smartest Labs house the best and the brightest engineers working with cutting-edge technologies including artificial intelligence, deep learning, machine learning, computer vision, and augmented reality to generate the best ideas.</MKTypography>
                    </Fade>
                </Box>

                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

                <Fade in={isVisible4} timeout={1000} ref={domRef2}>
                    <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#00000011', width: '90vw', height: '70vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`, zIndex: 1, backdropFilter: `blur(5px)` } : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', bgcolor: '#00000011', width: '90vw', height: '80vh', alignItems: 'center', alignSelf: 'center', marginTop: '30px', marginBottom: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`, zIndex: 1, backdropFilter: `blur(5px)` }}>
                        <Box sx={!mobileView ? { width: '50%' } : { display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
                            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                                position: 'relative',
                                maxWidth: '100%',
                                color: '#000',
                                textAlign: 'left',
                                fontSize: '25px',
                                fontWeight: '400',
                                margin: '30px',
                            } : {
                                width: '90%',
                                color: '#000',
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: '400',
                            }}>They are the beating heart of innovation, creating new ideas, developing the best experience to make dumb things smart.</MKTypography>
                        </Box>
                        <Box
                            sx={!mobileView ? {
                                minWidth: "50vw",
                                minHeight: '100%',
                                backgroundImage: `url(${lab2})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            } : {
                                minWidth: "100%",
                                minHeight: '50%',
                                backgroundImage: `url(${lab2})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                    </Box>
                </Fade>

                <Fade in={isVisible} timeout={1000} ref={domRef}>
                    <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#00000011', width: '90vw', height: '70vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`, zIndex: 1, backdropFilter: `blur(5px)` } : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', bgcolor: '#00000011', width: '90vw', height: '90vh', alignItems: 'center', alignSelf: 'center', marginTop: '30px', marginBottom: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`, zIndex: 1, backdropFilter: `blur(5px)` }}>
                        <Box
                            sx={!mobileView ? {
                                minWidth: "50vw",
                                minHeight: '100%',
                                backgroundImage: `url(${lab3})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            } : {
                                minWidth: "100%",
                                minHeight: '50%',
                                backgroundImage: `url(${lab3})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        />
                        <Box sx={!mobileView ? { width: '50%' } : { display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
                            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                                position: 'relative',
                                maxWidth: '100%',
                                color: '#000',
                                textAlign: 'left',
                                fontSize: '25px',
                                fontWeight: '400',
                                margin: '30px',
                            } : {
                                width: '90%',
                                color: '#000',
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: '400',
                            }}>Our talented software team is focused on bringing the next generation of technologies that make the best use of our incredible hardware. They are connecting extremely capable hardware with the most innovative software to put the user in the center of attention and pull the future closer than ever.</MKTypography>
                        </Box>
                    </Box>
                </Fade>

                <Box sx={!mobileView ? { display: 'flex', width: '100vw', height: '30vh', alignItems: 'center', justifyContent: 'center' } : { display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '400',
                        margin: '50px',
                    } : {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '50px',
                    }}>Smartest Company integrates autonomous driving artificial intelligence into motorcycle helmets to warn riders before possible dangerous situations happen on the road and provide active protection.</MKTypography>
                </Box>

                <StackCard mobileView={mobileView} title='Autonomous Driving Software Developed' subtitle='Using Computer Vision, Deep Learning, and Machine Learning' />

                <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        width: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '50px',
                        fontWeight: '800',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        width: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '30px',
                        fontWeight: '800',
                        margin: '20px',
                    }}>Object Detection & Tracking</MKTypography>
                </Box>

                <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '20px',
                    }}>Object Detection & Tracking stack can detect up to 80 different objects on the road that may be a danger to the rider.</MKTypography>
                </Box>

                <LabImageGrid mobileView={mobileView} img1={obj1} img2={obj2} />
                <LabImageGrid mobileView={mobileView} img1={org} img2={yolo} />
                <LabImageGrid mobileView={mobileView} img1={obj3} img2={obj4} />

                <Box sx={!mobileView ? {
                    display: "flex",
                    width: "100%",
                    height: "500px",
                    marginTop: '50px',
                    marginBottom: '50px'
                } : {
                    display: "flex",
                    width: "100%",
                    height: "300px",
                    marginTop: '50px',
                    marginBottom: '50px'
                }}>
                    <YoutubeEmbed embedId="fD5dBIlc0eA" />
                </Box>

                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

                <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        width: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '50px',
                        fontWeight: '800',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        width: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '30px',
                        fontWeight: '800',
                        margin: '20px',
                    }}>Depth Estimation</MKTypography>
                </Box>

                <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '20px',
                    }}>Depth Estimation stack creates a depth map by estimating the distances of every object on the road.</MKTypography>
                </Box>

                <LabImageGrid mobileView={mobileView} img1={depth1} img2={depth2} />

                <Box sx={{ display: 'flex', justifyContent: 'center', bgcolor: '#fff', width: '100vw', height: '50vh', alignItems: 'center', zIndex: 1, marginTop: '30px', marginBottom: '30px' }}>
                    <Box
                        sx={{
                            minWidth: "100vw",
                            minHeight: '50vh',
                            backgroundImage: `url(${moment1})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            minWidth: "100vw",
                            minHeight: '50vh',
                            backgroundImage: `url(${moment2})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            opacity: '0.5'
                        }}
                    />
                </Box>

                <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '20px',
                    }}>Instead of using two cameras or lidar sensors as in other autonomous vehicles, our artificial intelligence measures the depth by estimating the distance with just one camera.</MKTypography>
                </Box>

                <LabImageGrid mobileView={mobileView} img1={depth3} img2={depth4} />

                <Box sx={!mobileView ? {
                    display: "flex",
                    width: "100%",
                    height: "500px",
                    marginTop: '50px',
                    marginBottom: '50px'
                } : {
                    display: "flex",
                    width: "100%",
                    height: "300px",
                    marginTop: '50px',
                    marginBottom: '50px'
                }}>
                    <YoutubeEmbed embedId="7ylfPFWxo1c" />
                </Box>

                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

                <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        maxWidth: '100vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '20px',
                    }}>We are already training our AI to predict dangerous situations that riders may encounter and started to get the first danger predictions from our AI.</MKTypography>
                </Box>

                <Box sx={!mobileView ? {
                    display: "flex",
                    width: "100%",
                    height: "500px",
                    marginTop: '50px',
                    marginBottom: '50px'
                } : {
                    display: "flex",
                    width: "100%",
                    height: "300px",
                    marginTop: '50px',
                    marginBottom: '50px'
                }}>
                    <YoutubeEmbed embedId="emHaQFSUGFM" />
                </Box>

                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />


                <Box sx={{ display: 'flex', justifyContent: 'center', bgcolor: '#00000011', width: '90vw', height: '30vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`, zIndex: 1, backdropFilter: `blur(5px)` }}>
                    <Box
                        sx={{
                            minWidth: "40vw",
                            minHeight: '100%',
                            backgroundImage: `url(${hiring})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                    <Box sx={!mobileView ? { width: '50vw', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            position: 'relative',
                            maxWidth: '100%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '25px',
                            fontWeight: '800',
                            marginLeft: '30px',
                        } : {
                            position: 'relative',
                            maxWidth: '100%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '18px',
                            fontWeight: '800',
                            marginLeft: '10px',
                        }}>We Are Hiring!</MKTypography>
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            position: 'relative',
                            maxWidth: '100%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '20px',
                            fontWeight: '400',
                            margin: '30px',
                        } : {
                            position: 'relative',
                            maxWidth: '100%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '16px',
                            fontWeight: '400',
                            margin: '10px',
                        }}>Join us to work on the cutting edge technologies.</MKTypography>
                        <MKButton
                            component="a"
                            href='https://www.linkedin.com/company/pyz-realise-design-studio/jobs/?viewAsMember=true'
                            target="_blank"
                            rel="noreferrer"
                            variant="gradient"
                            color="primary"
                            size="small"
                            sx={!mobileView ? { fontFamily: "'M PLUS Rounded 1c', sans-serif", marginLeft: '30px' } : { fontFamily: "'M PLUS Rounded 1c', sans-serif", marginLeft: '10px' }}
                        >
                            View Jobs
                        </MKButton>
                    </Box>
                </Box>

                <MKBox sx={{ marginTop: '30px', zIndex: 1 }}>
                    <Footer content={footerRoutes} />
                </MKBox>
            </Box>
        </Box>
    )
}

export default LabPage

