import React from "react";
//import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
    <iframe
        width="90%"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Demo"
        style = {{ display: 'flex', justifyContent: 'center', bgcolor: '#fff', width: '100%', height: '100%', alignItems: 'center', alignSelf: 'center', margin: '10px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`, padding: "10px", zIndex: 1 }}
    />
);

//YoutubeEmbed.propTypes = {
//  embedId: PropTypes.string.isRequired
//};

export default YoutubeEmbed;