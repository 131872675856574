import React from 'react'

import './App.css';

import { Routes, Route, Navigate } from "react-router-dom";

//mui
import { ThemeProvider } from '@mui/material/styles';

//Pages
import HomePage from './pages/HomePage/HomePage';

import WishlistPage from './pages/WishlistPage/WishlistPage';

import DefaultNavbar from 'components/DefaultNavbar';

import MKBox from './MKcomponents/MKBox/index';

import theme from "./assets/theme";

import routes from 'components/DefaultNavbar/routes';

import T1Page from './pages/ProductPage/T1Page';
// import Sd1Page from 'pages/ProductPage/Sd1Page';
import CyberPage from 'pages/ProductPage/CyberPage';
import LabPage from 'pages/LabPage/LabPage';

import breakpoints from "assets/theme/base/breakpoints";
import SmartestHelmetPage from 'pages/ProductPage/SmartestHelmetPage';

// const theme = createTheme({
//   palette: {
//     primary: {
//       light: '#757ce8',
//       main: '#262626',
//       dark: '#000000',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff7961',
//       main: '#ff7961',
//       dark: '#ba000d',
//       contrastText: '#000',
//     },
//     text: {
//       light: '#ff7961',
//       main: '#f44336',
//       dark: '#ba000d',
//       contrastText: '#000',
//     },
//   },
// });

function App() {
  const [mobileView, setMobileView] = React.useState(false);

  React.useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();

    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <MKBox shadow="lg" py={0.25} sx={{ position: 'sticky', top: 0, bgcolor: '#fff9', backdropFilter: `blur(16px)`, zIndex: 10 }}>
          <DefaultNavbar
            routes={routes}
            action={{
              type: "internal",
              route: "/wishlist",
              label: "join the wishlist",
              color: "primary",
            }}
            transparent
            relative
            brand="SMARTEST Co."
            sticky
          />
        </MKBox>
        <div className="container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/smartestscooter" element={<T1Page mobileView={mobileView} />} />
            <Route path="/cyberscooter" element={<CyberPage mobileView={mobileView} />} />
            <Route path="/smartesthelmet" element={<SmartestHelmetPage mobileView={mobileView} />} />
            <Route path="/wishlist" element={<WishlistPage />} />
            <Route path="/smartestlab" element={<LabPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
}

/*
<Route path="/sd1" element={<Sd1Page mobileView={mobileView} />} />
<Route path="/cyberscooter" element={<CyberPage mobileView={mobileView} />} />
*/ 

export default App;
