import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import breakpoints from 'assets/theme/base/breakpoints';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Carousel({ images }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const [mobileView, setMobileView] = React.useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    React.useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    return (
        <Box sx={!mobileView ? { maxWidth: '60vw', maxHeight: '100vh', position: '-webkit-sticky', position: 'sticky', top: '0px' } : { maxWidth: '100vw', maxHeight: '100vh', position: '-webkit-sticky', position: 'sticky', top: '0px' }}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                sx={{
                                    height: 'auto',
                                    display: 'block',
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    width: 'auto',
                                    maxHeight: '100vh',
                                }}
                                src={step.imgPath}
                                alt={step.label}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                variant='dots'
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{bgcolor: '#f3f3f3'}}
                nextButton={
                    <Button
                        size="large"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}

                    >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft sx={{ color: '#000' }} />
                        ) : (
                            <KeyboardArrowRight sx={{ color: '#000' }} />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="large" onClick={handleBack} disabled={activeStep === 0} >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight sx={{ color: '#000' }} />
                        ) : (
                            <KeyboardArrowLeft sx={{ color: '#000' }} />
                        )}
                    </Button>
                }
            />
        </Box>
    );
}

export default Carousel;

// <Paper
//                 square
//                 elevation={0}
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     height: 50,
//                     pl: 2,
//                     bgcolor: '#000',
//                 }}
//             >
//                 <Typography sx={{color: 'text.light'}}>{images[activeStep].label}</Typography>
//             </Paper>