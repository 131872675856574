/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import FacebookIcon from "@mui/icons-material/Facebook";

// Material Kit 2 React components
import MKBox from "MKcomponents/MKBox";
import MKTypography from "MKcomponents/MKTypography";

function HorizontalTeamCard({ image, name, position, description, socials }) {
  return (
    <Card sx={{ mt: 3, bgcolor: '#fff5' }}>
      <Grid container>
        <Grid item xs={12} md={4} lg={4} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2} sx={{ maxWidth: '200px' }}>
            <MKBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="100%"
              shadow="lg"
            />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={8} lg={8} sx={{ my: "auto", textAlign: 'left', paddingLeft: '20px' }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography variant="h5" sx={{ color: '#000' }}>{name}</MKTypography>
            <MKTypography variant="h6" sx={{ color: '#000', letterSpacing: '2px' }} mb={1}>
              {position.label}
            </MKTypography>
            <MKTypography variant="body2" color="primary" sx={{ color: '#000' }}>
              {description}
            </MKTypography>
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                  sx={{ fontSize: '22px', color: '#000', transition: '0.5s', '&:hover': { color: '#e8a7dd' } }}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
