import React from 'react';

import './SlidingImages.css'

const SlidingImages = ({
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
}) => {
    return (
        <div id="sliding-container">
            <div class="photobanner">
                <img class="ind-image" src={image1} alt="" />
                <img class="ind-image2" src={image2} alt="" />
                <img class="ind-image" src={image3} alt="" />
                <img class="ind-image2" src={image4} alt="" />
                <img class="ind-image" src={image5} alt="" />
                <img class="ind-image2" src={image6} alt="" />
                <img class="ind-image" src={image7} alt="" />
                <img class="ind-image2" src={image8} alt="" />
                <img class="ind-image" src={image9} alt="" />
                <img class="ind-image2" src={image10} alt="" />
                <img class="ind-image" src={image1} alt="" />
                <img class="ind-image2" src={image2} alt="" />
                <img class="ind-image" src={image3} alt="" />
                <img class="ind-image2" src={image4} alt="" />
                <img class="ind-image" src={image5} alt="" />
                <img class="ind-image2" src={image6} alt="" />
                <img class="ind-image" src={image7} alt="" />
                <img class="ind-image2" src={image8} alt="" />
                <img class="ind-image" src={image9} alt="" />
                <img class="ind-image2" src={image10} alt="" />
            </div>
        </div>
    )
}

export default SlidingImages