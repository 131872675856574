import React from 'react'

import { Box } from '@mui/material'

const LabImageGrid = ({ mobileView, img1, img2 }) => {
    return (
        <Box sx={!mobileView ? { display: 'flex', justifyContent: 'space-around', width: '100vw', height: '40vh', alignItems: 'center', marginBottom: '10px', zIndex: 1 } : { display: 'flex', justifyContent: 'space-around', width: '100vw', height: '60vh', alignItems: 'center', flexDirection: 'column', zIndex: 1 }}>
            <Box
                sx={!mobileView ? {
                    minWidth: "45vw",
                    minHeight: '100%',
                    backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderRadius: '10px',
                    overflow: 'hidden'
                } : {
                    minWidth: "90%",
                    minHeight: '45%',
                    backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderRadius: '10px',
                    overflow: 'hidden'
                }}
            />
            <Box
                sx={!mobileView ? {
                    minWidth: "45vw",
                    minHeight: '100%',
                    backgroundImage: `url(${img2})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderRadius: '10px',
                    overflow: 'hidden'
                } : {
                    minWidth: "90%",
                    minHeight: '45%',
                    backgroundImage: `url(${img2})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderRadius: '10px',
                    overflow: 'hidden'
                }}
            />
        </Box>
    )
}

export default LabImageGrid