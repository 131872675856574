import * as React from 'react';
import Box from '@mui/material/Box';
// import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
// import FormHelperText from '@mui/material/FormHelperText';
// import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
// import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';

import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { FormHelperText } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ColorButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: '#EC407A',
    '&:hover': {
        backgroundColor: 'white',
        color: '#EC407A'
    },
    borderRadius: '20px'
}));


function WishlistForm({ onClick }) {
    const [firstName, setFirstName] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [email, setEmail] = React.useState('');

    const [selectedCountry, setSelectedCountry] = React.useState("");

    const selectCountryHandler = (value) => setSelectedCountry(value);

    // Have to register the languages you want to use
    countries.registerLocale(enLocale);
    countries.registerLocale(itLocale);

    // Returns an object not a list
    const countryObj = countries.getNames("en", { select: "official" });

    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        };
    });

    //Snack
    const [open, setOpen] = React.useState(false);

    const checkForm = () => {
        if (firstName && surname && email && selectedCountry) {
            onClick(firstName, surname, email, selectedCountry)
        } else {
            // console.log('hey')
            setOpen(true);
        }
    }

    return (
        <Box sx={{ margin: '10px', width: '100%' }}>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, display: 'flex', flex: 1 },
                }}
                noValidate
                autoComplete="off"
            >
                <Box sx={{ display: 'flex', flex: 1 }}>
                    <Input color="primary" placeholder='Name' required variant="filled" value={firstName} onChange={(e) => setFirstName(e.target.value)} style={{ marginTop: 11, marginRight: 11, color: '#000', flex: 1 }} />
                    <Input color="secondary" placeholder='Surname' required variant="filled" value={surname} onChange={(e) => setSurname(e.target.value)} style={{ marginTop: 11, flex: 1 }} />
                </Box>
                <Input
                    placeholder='Email'
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    variant="filled"
                    style={{ marginTop: 11 }}
                />
                <Box sx={{ minWidth: 120 }}>
                    <FormControl sx={{ m: 1, flex: 1 }}>
                        <InputLabel id="demo-select-small" sx={{ color: '#ccc' }}>Country</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            sx={{ flex: 1, }}
                            required
                            label="Country"
                            value={selectedCountry}
                            onChange={(e) => selectCountryHandler(e.target.value)}
                        >
                            {!!countryArr?.length &&
                                countryArr.map(({ label, value }) => (
                                    <MenuItem key={value} value={value} sx={{ color: '#EC407A' }}>
                                        {label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                {open ? <FormHelperText sx={{ color: '#000', padding: '5px', borderTopColor: '#0005', borderTopStyle: 'solid', borderTopWidth: '1px', textAlign: 'center' }}>Please fill empty fields!</FormHelperText> : null}
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <ColorButton variant="contained" onClick={checkForm}>Join The Wishlist</ColorButton>
                </Box>
            </Box>
        </Box>
    );
}

export default WishlistForm