import React, { Fragment } from 'react'

import "./HomePage.css"

import { Box, Divider, Fade, Slide } from '@mui/material'

// import image1 from "../../assets/images/t1/image1.png"

//Components
import ProductsGrid from '../../components/productsGrid/productsGrid'
// import InfoSection from '../../components/infoSection/infoSection'

import MKTypography from 'MKcomponents/MKTypography'
import Team from './Team'

import MKBox from 'MKcomponents/MKBox'
import Footer from 'components/footer/footer'
import footerRoutes from 'components/footer/footerRoutes'

import breakpoints from "assets/theme/base/breakpoints";

import bauhub from '../../assets/images/bauhub-logo.png'
import kworks from '../../assets/images/kworks-logo.png'
import cube from '../../assets/images/cube-logo.png'

import SmartestHelmetBig1 from '../../assets/images/SmartestHelmetConcept/1.png'
// import SmartestHelmetBig2 from '../../assets/images/SmartestHelmetConcept/2.png'
import SmartestHelmetBig3 from '../../assets/images/SmartestHelmetConcept/3.png'

import SmartestHelmetBig5 from '../../assets/images/SmartestHelmetConcept/5.png'

import vid from '../../assets/images/SmartestHelmetConcept/vid.mp4'

import MasonryImageList from 'components/mansonryImageList/mansonryImageList'

const itemData = [
    {
        img: SmartestHelmetBig5,
        title: 'SmartestHelmetBig1',
    },
    {
        img: SmartestHelmetBig3,
        title: 'SmartestHelmetBig3',
    },
]

const itemData2 = [
    {
        img: SmartestHelmetBig1,
        title: 'SmartestHelmetBig1',
        featured: true
    },
]


const HomePage = () => {
    const [isVisible2, setVisible2] = React.useState(false);
    const [isVisible3, setVisible3] = React.useState(false);
    const [isVisible4, setVisible4] = React.useState(false);
    const [isVisible5, setVisible5] = React.useState(false);

    const [mobileView, setMobileView] = React.useState(false);

    const domRef = React.useRef();
    const domRef2 = React.useRef();
    const domRef3 = React.useRef();

    const containerRef = React.useRef(null);
    const containerRef2 = React.useRef(null);

    React.useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible4(entry.isIntersecting));
        });

        if (domRef2.current) {
            observer.observe(domRef2.current);
            observerRefValue = domRef2.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible5(entry.isIntersecting));
        });

        if (domRef2.current) {
            observer.observe(domRef3.current);
            observerRefValue = domRef3.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible2(entry.isIntersecting));
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
            observerRefValue = containerRef.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible3(entry.isIntersecting));
        });

        if (containerRef2.current) {
            observer.observe(containerRef2.current);
            observerRefValue = containerRef2.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    return (
        <Fragment>
            <Box
                sx={{
                    display: 'flex',
                    minHeight: "100vh",
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    width: '90vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '80px',
                    fontWeight: 'bold',
                    fontFamily: "'M PLUS Rounded 1c', sans-serif",
                    letterSpacing: '5px',
                    alignSelf: 'center'
                } : {
                    width: '90vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '50px',
                    fontWeight: 'bold',
                    fontFamily: "'M PLUS Rounded 1c', sans-serif",
                    letterSpacing: '5px',
                    alignSelf: 'center'
                }}>Making Dumb Things Smart.</MKTypography>
            </Box>

            <Fade in={isVisible4} timeout={1000} ref={domRef2}>
                <Box
                    sx={{
                        display: 'flex',
                        minHeight: "30vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '50px',
                        fontWeight: 'bold',
                        fontFamily: "'M PLUS Rounded 1c', sans-serif",
                        letterSpacing: '5px',
                        alignSelf: 'center'
                    } : {
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '25px',
                        fontWeight: 'bold',
                        fontFamily: "'M PLUS Rounded 1c', sans-serif",
                        letterSpacing: '5px',
                        alignSelf: 'center'
                    }}>To make the smartest products we vertically integrate our three main pillars:</MKTypography>
                </Box>
            </Fade>

            <Box ref={containerRef} sx={{ display: 'flex', width: '100vw', height: '50vh', alignItems: 'center', justifyContent: 'center' }}>
                <Slide direction="right" timeout={2000} in={isVisible2} container={containerRef.current} mountOnEnter unmountOnExit>
                    <Box
                        sx={{
                            display: 'flex',
                            minHeight: "30vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '90vw',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '80px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        } : {
                            width: '90vw',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '50px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        }}>Cutting Edge Software</MKTypography>
                    </Box>
                </Slide>
            </Box>

            <Box ref={containerRef2} sx={{ display: 'flex', width: '100vw', height: '50vh', alignItems: 'center', justifyContent: 'center' }}>
                <Slide direction="left" timeout={2000} in={isVisible3} container={containerRef2.current} mountOnEnter unmountOnExit>
                    <Box
                        sx={{
                            display: 'flex',
                            minHeight: "30vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '90vw',
                            color: '#000',
                            textAlign: 'right',
                            fontSize: '80px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        } : {
                            width: '90vw',
                            color: '#000',
                            textAlign: 'right',
                            fontSize: '50px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        }}>Reliable Hardware</MKTypography>
                    </Box>
                </Slide>
            </Box>

            <Fade in={isVisible5} timeout={3000} ref={domRef3}>
                <Box
                    sx={{
                        display: 'flex',
                        minHeight: "30vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '90vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '80px',
                        fontWeight: 'bold',
                        fontFamily: "'M PLUS Rounded 1c', sans-serif",
                        letterSpacing: '5px',
                        alignSelf: 'center'
                    } : {
                        width: '90vw',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '40px',
                        fontWeight: 'bold',
                        fontFamily: "'M PLUS Rounded 1c', sans-serif",
                        letterSpacing: '5px',
                        alignSelf: 'center'
                    }}>Unparalleled Design</MKTypography>
                </Box>
            </Fade>

            <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', flexDirection: 'column', bgcolor: '#f3f3f3' }}>
                <MasonryImageList itemData={itemData2} />
            </Box>

            <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', flexDirection: 'column', bgcolor: '#f3f3f3' }}>
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    position: 'relative',
                    width: '100vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '40px',
                    letterSpacing: '10px',
                    fontWeight: '500',
                } : {
                    position: 'relative',
                    width: '100vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: '400',
                    letterSpacing: '2px',
                }}>Smartest Helmet</MKTypography>
                <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '80%', marginTop: '50px' }} />
                <MasonryImageList itemData={itemData} />
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    position: 'relative',
                    width: '100vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '40px',
                    letterSpacing: '6px',
                    fontWeight: 'light',
                    marginTop: '30px'
                } : {
                    position: 'relative',
                    width: '90vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: '400',
                    letterSpacing: '2px',
                    marginTop: '10px'
                }}>World's first motorcycle helmet with Danger Prediction & Warning system</MKTypography>
                <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', width: '100vw', height: '100vh', alignItems: 'center', bgcolor: '#f3f3f3' } : { display: 'flex', justifyContent: 'center', width: '100vw', height: '50vh', alignItems: 'center', bgcolor: '#f3f3f3' }}>
                    <video controls loop mutedn style={{ width: '100%', maxHeight: '100%' }} ref={domRef}>
                        <source src={vid} type="video/mp4"></source>
                    </video>
                </Box>
                <ProductsGrid />
            </Box>

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

            <Box sx={{ display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={!mobileView ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#fff', width: '90%', height: '60vh', alignItems: 'flex-start', alignSelf: 'center', marginTop: '30px', marginBottom: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 10px` } : { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', bgcolor: '#fff', width: '90%', alignItems: 'flex-start', alignSelf: 'center', marginTop: '30px', marginBottom: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}>

                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '40px',
                        fontWeight: '800',
                        marginLeft: '30px',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '30px',
                        fontWeight: '800',
                        marginTop: '30px',
                        marginBottom: '30px'
                    }}>Our Goal</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: '400',
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '40px'
                    }}>Smartest Company develops “smartest” products that seamlessly integrate in people’s daily life
                        by adding AI and computer vision in order to make human life safer, more enjoyable and more
                        interactive. Our first product, Smartest Helmet is the world&#39;s first motorcycle helmet with a
                        danger prediction and warning system that prevents accidents by warning riders of possible
                        dangers.</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '20px',
                        fontWeight: 'light',
                        margin: '30px',
                    } : {
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: 'light',
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '30px'
                    }}>We are already training our AI to predict dangerous situations that riders may
                        encounter and started to get the first danger predictions and warnings on video from our AI.</MKTypography>
                </Box>
            </Box>

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

            <Team />

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '100vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                letterSpacing: '10px',
                fontWeight: 'light',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '100vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '400',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Smartest Partners</MKTypography>

            <Box sx={!mobileView ? { display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'space-around' } : { display: 'flex', width: '100vw', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <MKBox
                    component="a"
                    href='https://bau-hub.com'
                    target="_blank"
                    rel="noreferrer"
                    shadow="lg"
                    className="justTesting"
                    sx={!mobileView ? {
                        display: 'flex',
                        minHeight: "30vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: "30vw",
                        position: 'relative',
                        borderRadius: "10%",
                        transition: "all 0.15s ease-in-out",
                        textDecoration: 'none',
                        marginTop: '20px',
                        marginBottom: '50px',
                        '&:hover': {
                            cursor: 'pointer',
                            boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 10px`,
                        },
                    } : {
                        display: 'flex',
                        minHeight: "15vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: "90%",
                        borderRadius: "20px",
                        textDecoration: 'none',
                        marginTop: '10px',
                    }}
                >
                    <Box
                        sx={!mobileView ? {
                            minHeight: "10vh",
                            width: "100%",
                            backgroundImage: `url(${bauhub})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        } : {
                            minHeight: "6vh",
                            width: '100%',
                            backgroundImage: `url(${bauhub})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                </MKBox>
                <MKBox
                    component="a"
                    href='https://kworks.ku.edu.tr/en/'
                    target="_blank"
                    rel="noreferrer"
                    shadow="lg"
                    sx={!mobileView ? {
                        display: 'flex',
                        minHeight: "30vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: "30vw",
                        position: 'relative',
                        borderRadius: "10%",
                        transition: "all 0.15s ease-in-out",
                        textDecoration: 'none',
                        marginTop: '20px',
                        marginBottom: '50px',
                        '&:hover': {
                            cursor: 'pointer',
                            boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 10px`,
                        },
                    } : {
                        display: 'flex',
                        minHeight: "15vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: "90%",
                        borderRadius: "20px",
                        textDecoration: 'none',
                        marginTop: '10px',
                    }}
                >
                    <Box
                        sx={!mobileView ? {
                            minHeight: "10vh",
                            width: "100%",
                            backgroundImage: `url(${kworks})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        } : {
                            minHeight: "6vh",
                            width: '100%',
                            backgroundImage: `url(${kworks})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                </MKBox>
                <MKBox
                    component="a"
                    href='https://www.cubeincubation.com'
                    target="_blank"
                    rel="noreferrer"
                    shadow="lg"
                    sx={!mobileView ? {
                        display: 'flex',
                        minHeight: "30vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: "30vw",
                        position: 'relative',
                        borderRadius: "10%",
                        transition: "all 0.15s ease-in-out",
                        textDecoration: 'none',
                        marginTop: '20px',
                        marginBottom: '50px',
                        '&:hover': {
                            cursor: 'pointer',
                            boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 10px`,
                        },
                    } : {
                        display: 'flex',
                        minHeight: "15vh",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        width: "90%",
                        borderRadius: "20px",
                        textDecoration: 'none',
                        marginTop: '10px',
                        marginBottom: '50px',
                    }}
                >
                    <Box
                        sx={!mobileView ? {
                            minHeight: "10vh",
                            width: "100%",
                            backgroundImage: `url(${cube})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        } : {
                            minHeight: "6vh",
                            width: '100%',
                            backgroundImage: `url(${cube})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                </MKBox>
            </Box>

            <MKBox>
                <Footer content={footerRoutes} />
            </MKBox>
        </Fragment>
    )
}

export default HomePage