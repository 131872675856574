import * as React from 'react';

import Box from '@mui/material/Box';
import { Fade } from '@mui/material';

import MasonryImageList from '../../components/mansonryImageList/mansonryImageList';
import SpecGrid from '../../components/specGrid/specGrid';

import cyberlogo from '../../assets/images/Cyberscooter/cyberscooterlogo2.png'

import CyberScooterBig1 from '../../assets/images/Cyberscooter/CyberScooterBig1.jpeg'
import CyberScooterBig2 from '../../assets/images/Cyberscooter/CyberScooterBig2.jpeg'
import CyberScooterBig3 from '../../assets/images/Cyberscooter/CyberScooterBig3.jpeg'

import CyberScooterSmall1 from '../../assets/images/Cyberscooter/CyberScooterSmall1.jpeg'
import CyberScooterSmall2 from '../../assets/images/Cyberscooter/CyberScooterSmall2.jpeg'
import CyberScooterSmall3 from '../../assets/images/Cyberscooter/CyberScooterSmall3.jpeg'

import MKTypography from 'MKcomponents/MKTypography';
import MKBox from 'MKcomponents/MKBox';
import Footer from 'components/footer/footer';
import footerRoutes from 'components/footer/footerRoutes';

const itemData = [
    {
        img: CyberScooterSmall3,
        title: 'CyberScooterSmall3',
        featured: true,
    },
    {
        img: CyberScooterSmall1,
        title: 'CyberScooterSmall1',

    },
    {
        img: CyberScooterSmall2,
        title: 'CyberScooterSmall2',
    }
];

const itemData2 = [
    {
        img: CyberScooterBig3,
        title: 'CyberScooterBig3',
        featured: true,
    },
]

const specs = [
    {
        first: 'Range:',
        second: '200 km'
    },
    {
        first: 'Weight:',
        second: '20 kg'
    },
    {
        first: 'Speed:',
        second: '32 km/h'
    },
    {
        first: 'Motor Power:',
        second: '2 x 350 W'
    },
    {
        first: 'Max Payload:',
        second: '200 kg'
    },
    {
        first: 'Material:',
        second: 'Aluminium'
    }
]

const CyberPage = ({ mobileView }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', bgcolor: '#000' }}>
            <Fade in={true} timeout={2000}>
                <Box
                    sx={!mobileView ? {
                        width: '100vw',
                        minHeight: "80vh",
                        backgroundImage: `url(${CyberScooterBig1})`,
                        backgroundSize: "100vw auto",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "start",
                    } : {
                        width: '100vw',
                        minHeight: "50vh",
                        backgroundImage: `url(${CyberScooterBig1})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                </Box>
            </Fade>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                }}
            >
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    color: '#7143d7',
                    width: '100vw',
                    fontSize: '100px',
                    fontWeight: 'light',
                    textAlign: 'left',
                    fontFamily: "'Silkscreen', cursive"
                } : {
                    color: '#7143d7',
                    width: '100vw',
                    fontSize: '50px',
                    fontWeight: 'light',
                    textAlign: 'left',
                    fontFamily: "'Silkscreen', cursive"
                }}>Cyber Life</MKTypography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    width: '100vw',
                    height: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1,
                        maxWidth: '50vw'
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#B749CA',
                        width: '100%',
                        fontSize: '80px',
                        paddingLeft: '10px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        fontFamily: "'Silkscreen', cursive"
                    } : {
                        color: '#B749CA',
                        width: '100%',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        fontFamily: "'Silkscreen', cursive"
                    }}>Station</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#B749CA',
                        width: '100%',
                        fontSize: '120px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                        fontFamily: "'Silkscreen', cursive"
                    } : {
                        color: '#B749CA',
                        width: '100%',
                        fontSize: '50px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                        fontFamily: "'Silkscreen', cursive"
                    }}>Power</MKTypography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#4B00FE',
                        width: '100%',
                        fontSize: '80px',
                        marginLeft: '10px',
                        fontWeight: 'light',
                        textAlign: 'right',
                        fontFamily: "'Silkscreen', cursive"
                    } : {
                        color: '#4B00FE',
                        width: '100%',
                        fontSize: '25px',
                        fontWeight: 'light',
                        textAlign: 'right',
                        fontFamily: "'Silkscreen', cursive"
                    }}>Unorthodox</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#4B00FE',
                        fontSize: '120px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-lr',
                        fontFamily: "'Silkscreen', cursive"
                    } : {
                        color: '#4B00FE',
                        fontSize: '30px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-lr',
                        fontFamily: "'Silkscreen', cursive"
                    }}>Design</MKTypography>
                </Box>
            </Box>
            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '70vw',
                color: '#fff',
                textAlign: 'center',
                top: '-200px',
                fontSize: '30px',
                letterSpacing: '5px',
                fontWeight: 'light',
            } : {
                position: 'relative',
                width: '70vw',
                color: '#fff',
                textAlign: 'center',
                top: '-70px',
                fontSize: '20px',
                letterSpacing: '3px',
                fontWeight: 'light',
            }}>We don't know why it can go this long. I mean it's just unnecessary.</MKTypography>
            <MasonryImageList itemData={itemData2} />
            <Box sx={{
                display: 'flex',
                height: '50vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    width: '70vw',
                    color: '#fff',
                    textAlign: 'center',
                    fontSize: '30px',
                    letterSpacing: '5px',
                    fontWeight: 'light',
                    paddingBottom: '50px'
                } : {
                    width: '90vw',
                    color: '#fff',
                    textAlign: 'center',
                    fontSize: '20px',
                    letterSpacing: '3px',
                    fontWeight: 'light',
                    paddingBottom: '50px'
                }}>The e-scooter designs have been the same for years. When you remove the
                    brands, you cannot tell which scooter belongs to which brand.
                    <br /> <MKTypography sx={!mobileView ? {
                        width: '70vw',
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: '30px',
                        letterSpacing: '5px',
                        fontWeight: 'bold',
                        paddingBottom: '50px'
                    } : {
                        width: '90vw',
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: '20px',
                        letterSpacing: '5px',
                        fontWeight: 'bold',
                        paddingBottom: '50px'
                    }}>We need something different.</MKTypography></MKTypography>
            </Box>
            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#fff', width: '100vw', height: '70vh', alignItems: 'center' } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#fff', width: '100vw', height: '100vh', alignItems: 'center' }}>
                <SpecGrid specs={specs} style={!mobileView ? { width: '500px', paddingRight: '30px', paddingLeft: '30px' } : { width: '90%' }} removable={false} />
                <Box
                    sx={!mobileView ? {
                        minWidth: "40vw",
                        minHeight: '100%',
                        backgroundImage: `url(${CyberScooterBig2})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } : {
                        minWidth: "100vw",
                        minHeight: '50%',
                        backgroundImage: `url(${CyberScooterBig2})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <MKTypography variant="h1" component="h3" sx={{
                    width: '100vw',
                    background: `-webkit-linear-gradient(right, #4B00FE, #B749CA)`,
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    fontSize: '100px',
                    fontWeight: 'light',
                    textAlign: 'center',
                    fontFamily: "'Silkscreen', cursive",
                    marginTop: '50px',
                }}>Cyber In The Wild</MKTypography>
            </Box>
            <MasonryImageList itemData={itemData} />
            <Box
                sx={{
                    width: '100vw',
                    height: "200px",
                    backgroundImage: `url(${cyberlogo})`,
                    backgroundSize: "100vw auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    marginTop: '10px'
                }}
            >
            </Box>
            <MKBox pt={6} px={1} mt={6}>
                <Footer content={footerRoutes} />
            </MKBox>
        </Box >
    )
}

export default CyberPage

// <Box
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'flex-start',
//                     flexDirection: 'row',
//                     width: '100vw'
//                 }}
//             >
//                 <Box sx={{ flex: 1 }}>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '60px',
//                         marginRight: '10px',
//                         textAlign: 'right',
//                         transform: 'rotate(170deg)',
//                         marginLeft: '10px'
//                     }}>Your</MKTypography>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '60px',
//                         writingMode: 'vertical-lr'
//                     }}>Power</MKTypography>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '60px',
//                         textAlign: 'left',
//                         marginLeft: '10px'
//                     }}>Station</MKTypography>
//                 </Box>
//                 <Box sx={{ flex: 1 }}>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '100px',
//                         marginRight: '10px',
//                         textAlign: 'right'
//                     }}>Flight</MKTypography>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '100px',
//                         writingMode: 'vertical-rl'
//                     }}>Friendly</MKTypography>
//                 </Box>
//             </Box>