import React from 'react';
import { styled } from '@mui/material/styles';

import { Link } from "react-router-dom";

import image3 from "../../assets/images/SmartestScooter/SmartestScooterBig1.png"
import handbag2 from "../../assets/images/SmartestScooter/SmartestScooterBag.png"

import cyber from "../../assets/images/Cyberscooter/CyberScooterBig1.jpeg"
import sd1 from "../../assets/images/SD1-2/SD1Homepage.jpg"

import helmet3 from "../../assets/images/SmartestHelmet/SmartestHelmetPlaceholder.png"

import sh from "../../assets/images/SmartestHelmetConcept/9.png"

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider } from '@mui/material'
import MKBox from 'MKcomponents/MKBox';
import MKTypography from 'MKcomponents/MKTypography';
import MKButton from 'MKcomponents/MKButton';

import breakpoints from "assets/theme/base/breakpoints";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: `transparent`,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#000',
    width: '100%',
    fontWeight: 'bold',
    fontFamily: "'M PLUS Rounded 1c', sans-serif",
    fontSize: '22px',
}));

export default function ProductsGrid() {
    const [mobileView, setMobileView] = React.useState(false)

    React.useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    return (
        <Box mt='10px' sx={!mobileView ? { flexGrow: 1, padding: '20px' } : { flexGrow: 1, padding: '10px' }}>

            <Grid container spacing={4} >
                <Grid xs={12} md={12} sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#f3f3f3', width: '80vw', height: '50vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 10px` } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', bgcolor: '#f3f3f3', width: '90vw', height: '80vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 10px` }}>
                    <MKBox shadow="lg"
                        component={Link}
                        to='/smartesthelmet'
                        sx={!mobileView ? {
                            width: '50%',
                            minHeight: "100%",
                            backgroundColor: `transparent`,
                            backgroundImage: `url(${sh})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            transition: "transform 0.15s ease-in-out",
                            transition: "width 1s ease-in-out",
                            textDecoration: 'none',
                            '&:hover': {
                                width: '100%',
                                cursor: 'pointer',
                                transform: "scale3d(1.02, 1.02, 1)",
                            },
                            borderRadius: '30px',
                            overflow: 'hidden'
                        } : {
                            minWidth: '100%',
                            height: "60%",
                            backgroundColor: `transparent`,
                            backgroundImage: `url(${sh})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            transition: "transform 0.15s ease-in-out",
                            transition: "width 1s ease-in-out",
                            textDecoration: 'none',
                            '&:hover': {
                                width: '100%',
                                cursor: 'pointer',
                                transform: "scale3d(1.02, 1.02, 1)",
                            },
                            borderRadius: '30px',
                            overflow: 'hidden'
                        }}
                    >
                    </MKBox>
                    <MKBox
                        sx={!mobileView ? {
                            width: '50%',
                            minHeight: "100%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'flex-start',
                            marginLeft: '30px'
                        } : {
                            minWidth: '100%',
                            height: "30%",
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Box>
                            <MKTypography variant="h1" component="h3" sx={{
                                position: 'relative',
                                maxWidth: '100%',
                                color: '#000',
                                textAlign: 'left',
                                fontSize: '30px',
                                fontWeight: '800',
                            }}>Smartest Helmet</MKTypography>
                            <MKTypography variant="h1" component="h3" sx={{
                                position: 'relative',
                                maxWidth: '100%',
                                color: '#000',
                                textAlign: 'left',
                                fontSize: '20px',
                                fontWeight: '600',
                            }}>Actually Smart!</MKTypography>
                        </Box>

                        <MKButton
                            component={Link}
                            to='/smartesthelmet'
                            variant="gradient"
                            color="primary"
                            size="medium"
                            sx={{ fontFamily: "'M PLUS Rounded 1c', sans-serif" }}
                        >
                            Details
                        </MKButton>
                    </MKBox>
                </Grid>
            </Grid>






            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />

            <Box
                sx={{
                    display: 'flex',
                    minHeight: "20vh",
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <MKTypography variant="h1" component="h3" sx={{
                    width: '90vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '40px',
                    fontWeight: 'bold',
                    fontFamily: "'M PLUS Rounded 1c', sans-serif",
                    letterSpacing: '5px',
                    alignSelf: 'center'
                }}>Concepts</MKTypography>
            </Box>

            <Grid container spacing={4}>
                <Grid xs={12} md={8}>
                    <MKBox shadow="lg"
                        component={Link}
                        to='/smartestscooter'
                        sx={{
                            minHeight: "40vh",
                            backgroundImage: `url(${image3})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            transition: "transform 0.15s ease-in-out",
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                transform: "scale3d(1.05, 1.05, 1)",
                            },
                            borderRadius: '30px',
                            overflow: 'hidden'
                        }}
                    >
                        <Item>Smartest Scooter</Item>
                    </MKBox>
                </Grid>
                <Grid xs={12} md={4}>
                    <MKBox shadow="lg"
                        component={Link}
                        to='/smartestscooter/#accessories'
                        sx={{
                            minHeight: "40vh",
                            backgroundImage: `url(${handbag2})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            transition: "transform 0.15s ease-in-out",
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                transform: "scale3d(1.05, 1.05, 1)",
                            },
                            borderRadius: '30px',
                            overflow: 'hidden'

                        }}
                    >
                        <Item sx={{ color: '#fff' }}>Accessories</Item>
                    </MKBox>
                </Grid>
            </Grid>



        </Box>
    );
}

/*
<Divider variant="middle" sx={{ bgcolor: '#000', color: '#000' }} />
<Grid container spacing={4}>
                <Grid xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            minHeight: "40vh",
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <MKTypography variant="h1" component="h3" sx={{
                            width: '100%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '40px',
                            fontWeight: 'bold',
                            fontFamily: "'M PLUS Rounded 1c', sans-serif",
                            letterSpacing: '5px',
                            alignSelf: 'center'
                        }}>Concept Products</MKTypography>
                    </Box>
                </Grid>
                <Grid xs={12} md={4}>
                    <MKBox shadow="lg"
                        component={Link}
                        to='/cyberscooter'
                        sx={{
                            minHeight: "40vh",
                            backgroundImage: `url(${cyber})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            transition: "transform 0.15s ease-in-out",
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                transform: "scale3d(1.05, 1.05, 1)",
                            },
                            borderRadius: '30px',
                            overflow: 'hidden'
                        }}
                    >
                        <Item sx={{ color: '#fff' }}>CyberScooter</Item>
                    </MKBox>
                </Grid>
                <Grid xs={12} md={4}>
                    <MKBox shadow="lg"
                        component={Link}
                        to='/sd1'
                        sx={{
                            minHeight: "40vh",
                            backgroundImage: `url(${sd1})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            transition: "transform 0.15s ease-in-out",
                            textDecoration: 'none',
                            '&:hover': {
                                cursor: 'pointer',
                                transform: "scale3d(1.05, 1.05, 1)",
                            },
                            borderRadius: '30px',
                            overflow: 'hidden'
                        }}
                    >
                        <Item sx={{ color: '#fff' }}>SD1</Item>
                    </MKBox>
                </Grid>
            </Grid>
*/