import React from 'react'

import objectIcon from '../../assets/images/objectIcon.png'
import distanceIcon from '../../assets/images/distanceIcon.png'
import laneIcon from '../../assets/images/laneIcon.png'

import { Box } from '@mui/material'

import MKTypography from 'MKcomponents/MKTypography'
import MKButton from 'MKcomponents/MKButton'

import { Link } from "react-router-dom";

const StackCard = ({ mobileView, title, subtitle, helmetpage }) => {
    return (
        <Box sx={!mobileView ? {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            bgcolor: '#00000011',
            width: '90vw',
            height: '70vh',
            alignItems: 'center',
            alignSelf: 'center',
            margin: '30px',
            borderRadius: '20px',
            overflow: 'hidden',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
            zIndex: 1,
            backdropFilter: `blur(5px)`
        } : {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            bgcolor: '#00000011',
            width: '90vw',
            alignItems: 'center',
            alignSelf: 'center',
            marginTop: '30px',
            marginBottom: '30px',
            borderRadius: '20px',
            overflow: 'hidden',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
            zIndex: 1,
            backdropFilter: `blur(5px)`
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    position: 'relative',
                    maxWidth: '90%',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '35px',
                    fontWeight: '800',
                } : {
                    width: '90%',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: '800',
                    marginTop: '30px'
                }}>{title}</MKTypography>
                <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                    position: 'relative',
                    maxWidth: '90%',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: '400',
                } : {
                    width: '90%',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: '400',
                    marginBottom: '30px'
                }}>{subtitle}</MKTypography>
            </Box>
            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' } : { display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%', alignItems: 'center', margin: '10px' }}>
                    <Box
                        sx={!mobileView ? {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                            borderRadius: '10px',
                        } : {
                            display: 'flex',
                            width: '90%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px',
                            borderTopColor: '#ccc',
                            paddingTop: '20px'
                        }}>
                        <Box
                            sx={!mobileView ? {
                                width: "100px",
                                height: '100px',
                                backgroundImage: `url(${objectIcon})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                bgcolor: '#000',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            } : {
                                width: "80px",
                                height: '80px',
                                backgroundImage: `url(${objectIcon})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                bgcolor: '#000',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            }}
                        />
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '100%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '600',
                        } : {
                            color: '#000',
                            textAlign: 'right',
                            fontSize: '15px',
                            fontWeight: '400',
                        }}>Object Detection <br /> & Tracking</MKTypography>
                    </Box>
                    <MKButton
                        component="a"
                        href='https://youtu.be/fD5dBIlc0eA'
                        target="_blank"
                        rel="noreferrer"
                        variant="gradient"
                        color="transparent"
                        size="small"
                        sx={!mobileView ? {
                            fontFamily: "'M PLUS Rounded 1c', sans-serif", bgcolor: '#000', borderRadius: '50px', marginTop: '20px', '&:hover': {
                                bgcolor: '#ffffff00',
                                borderColor: '#000',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                color: '#000',
                                cursor: 'pointer'
                            },
                        } : {
                            fontFamily: "'M PLUS Rounded 1c', sans-serif", bgcolor: '#000', borderRadius: '50px', marginBottom: '10px', '&:hover': {
                                bgcolor: '#ffffff00',
                                borderColor: '#000',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                color: '#000',
                                cursor: 'pointer'
                            },
                        }}
                    >
                        Demo
                    </MKButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center', margin: '10px' }}>
                    <Box sx={!mobileView ? {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        borderRadius: '10px'
                    } : {
                        display: 'flex',
                        width: '90%',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '30px',
                        borderTopStyle: 'solid',
                        borderTopWidth: '1px',
                        borderTopColor: '#ccc',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: '#ccc'
                    }}>
                        <Box
                            sx={!mobileView ? {
                                width: "100px",
                                height: '100px',
                                backgroundImage: `url(${laneIcon})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                bgcolor: '#000',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            } : {
                                width: "80px",
                                height: '80px',
                                backgroundImage: `url(${laneIcon})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                bgcolor: '#000',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            }}
                        />
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '100%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '600',
                        } : {
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '15px',
                            fontWeight: '400',
                        }}>Lane Detection</MKTypography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%', alignItems: 'center', margin: '10px' }}>
                    <Box sx={!mobileView ? {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px',
                        borderRadius: '10px',
                    } : {
                        display: 'flex',
                        width: '90%',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                    }}>
                        <Box
                            sx={!mobileView ? {
                                width: "100px",
                                height: '100px',
                                backgroundImage: `url(${distanceIcon})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                bgcolor: '#000',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            } : {
                                width: "80px",
                                height: '80px',
                                backgroundImage: `url(${distanceIcon})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                bgcolor: '#000',
                                borderRadius: '100%',
                                overflow: 'hidden'
                            }}
                        />
                        <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                            width: '100%',
                            color: '#000',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '600',
                        } : {
                            color: '#000',
                            textAlign: 'right',
                            fontSize: '15px',
                            fontWeight: '400',
                        }}>Depth Estimation</MKTypography>
                    </Box>
                    <MKButton
                        component="a"
                        href='https://youtu.be/7ylfPFWxo1c'
                        target="_blank"
                        rel="noreferrer"
                        variant="gradient"
                        color="transparent"
                        size="small"
                        sx={!mobileView ? {
                            fontFamily: "'M PLUS Rounded 1c', sans-serif", bgcolor: '#000', borderRadius: '50px', marginTop: '20px', '&:hover': {
                                bgcolor: '#ffffff00',
                                borderColor: '#000',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                color: '#000',
                                cursor: 'pointer'
                            },
                        } : {
                            fontFamily: "'M PLUS Rounded 1c', sans-serif", bgcolor: '#000', borderRadius: '50px', marginBottom: '10px', '&:hover': {
                                bgcolor: '#ffffff00',
                                borderColor: '#000',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                color: '#000',
                                cursor: 'pointer'
                            },
                        }}
                    >
                        Demo
                    </MKButton>
                </Box>
            </Box>
            {helmetpage ? <MKButton
                component={Link}
                to='/smartestlab'
                variant="gradient"
                color="transparent"
                size="medium"
                sx={{
                    fontFamily: "'M PLUS Rounded 1c', sans-serif",
                    bgcolor: '#000',
                    borderRadius: '50px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    '&:hover': {
                        bgcolor: '#ffffff00',
                        borderColor: '#000',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: '#000',
                        cursor: 'pointer'
                    },
                }}
            >
                Learn More In Smartest Labs
            </MKButton> : null}
        </Box>
    )
}

export default StackCard