import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.transparent,
    ...theme.typography.body2,
    textAlign: 'start',
    fontSize: '20px',
    color: theme.palette.primary.dark,
    width: '100%',
    borderColor: '#fff',
    borderWidth: '1px',
    borderStyle: 'solid'
}));

const SpecGrid = ({ specs, style, removable = true }) => {
    return (
        <Box sx={style}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {specs.map((item) => {
                    return (
                        <Fragment key={item.first}>
                            <Grid xs={6}>
                                <Item sx={{ boxShadow: '0px 0px 0px #F4AAB9', }}>{item.first}</Item>
                            </Grid>
                            <Grid xs={6}>
                                <Item sx={{ boxShadow: '0px 0px 0px #F4AAB9', }}>{item.second}</Item>
                            </Grid>
                        </Fragment>
                    )
                })}
                {removable ? <Grid xs={12} sx={{}}>
                    <Item sx={{ textAlign: 'center', boxShadow: '0px 0px 0px #F4AAB9', }}>*With Removable Battery</Item>
                </Grid> : null}
            </Grid>
        </Box>
    );
}

export default SpecGrid