import * as React from 'react';

import Box from '@mui/material/Box';
import { Divider, Fade } from '@mui/material';

import MasonryImageList from '../../components/mansonryImageList/mansonryImageList';
import SpecGrid from '../../components/specGrid/specGrid';

import { useLocation } from "react-router-dom";

import SmartestScooterBig1 from '../../assets/images/SmartestScooter/SmartestScooterBig1.png'
import SmartestScooterBig2 from '../../assets/images/SmartestScooter/SmartestScooterBig2.png'
import SmartestScooterBig3 from '../../assets/images/SmartestScooter/SmartestScooterBig3.png'

import SmartestScooterBag from '../../assets/images/SmartestScooter/SmartestScooterBag.png'

import SmartestScooterExtensionBig1 from '../../assets/images/SmartestScooter/SmartestScooterExtensionBig1.png'
import SmartestScooterExtensionBig2 from '../../assets/images/SmartestScooter/SmartestScooterExtensionBig2.png'
import SmartestScooterExtensionBig3 from '../../assets/images/SmartestScooter/SmartestScooterExtensionBig3.png'

import SmartestScooterExtensionSmall1 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall1.png'
import SmartestScooterExtensionSmall2 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall2.png'
import SmartestScooterExtensionSmall3 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall3.png'
import SmartestScooterExtensionSmall4 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall4.png'

import SmartestScooterMac from '../../assets/images/SmartestScooter/SmartestScooterMac.png'

import SmartestScooterSmall1 from '../../assets/images/SmartestScooter/SmartestScooterSmall1.png'
import SmartestScooterSmall2 from '../../assets/images/SmartestScooter/SmartestScooterSmall2.png'
import SmartestScooterSmall3 from '../../assets/images/SmartestScooter/SmartestScooterSmall3.png'
import SmartestScooterSmall4 from '../../assets/images/SmartestScooter/SmartestScooterSmall4.png'

import SmartestScooterApp from '../../assets/images/SmartestScooter/SmartestScooterApp.png'
import Notification from '../../assets/images/SmartestScooter/FPS.png'

import MKTypography from 'MKcomponents/MKTypography';
import MKBox from 'MKcomponents/MKBox';
import Footer from 'components/footer/footer';
import footerRoutes from 'components/footer/footerRoutes';

const itemData = [
    {
        img: SmartestScooterSmall1,
        title: 'SmartestScooterSmall1',
    },
    {
        img: SmartestScooterSmall2,
        title: 'SmartestScooterSmall2',

    },
    {
        img: SmartestScooterBig3,
        title: 'SmartestScooterBig3',
        featured: true,
    },
    {
        img: SmartestScooterSmall3,
        title: 'SmartestScooterSmall3',
    },
    {
        img: SmartestScooterSmall4,
        title: 'SmartestScooterSmall4',
    },
];

const itemData3 = [

    {
        img: SmartestScooterExtensionBig2,
        title: 'SmartestScooterExtensionBig2',
        featured: true,
    },
    {
        img: SmartestScooterExtensionSmall3,
        title: 'SmartestScooterExtensionSmall3',
        featured: true,
    },
    {
        img: SmartestScooterExtensionBig3,
        title: 'SmartestScooterExtensionBig3',
        featured: true,
    },
]

const itemData2 = [
    {
        img: SmartestScooterExtensionBig1,
        title: 'SmartestScooterExtensionBig1',
        featured: true,
    },
    {
        img: SmartestScooterExtensionSmall4,
        title: 'SmartestScooterExtensionSmall4',
        featured: true,
    },
    {
        img: SmartestScooterExtensionSmall1,
        title: 'SmartestScooterExtensionSmall1',
    },
    {
        img: SmartestScooterExtensionSmall2,
        title: 'SmartestScooterExtensionSmall2',
    },
]

const itemData4 = [
    {
        img: SmartestScooterBag,
        title: 'SmartestScooterBag',
        featured: true,
    },
]

const specs = [
    {
        first: 'Weight:',
        second: '5 kg'
    },
    {
        first: 'Speed:',
        second: '25 km/h'
    },
    {
        first: 'Range:',
        second: '25 km'
    },
    {
        first: 'Motor Power:',
        second: '2 x 150 W'
    },
    {
        first: 'Motor Type:',
        second: 'Hub Motor'
    },
    {
        first: 'Material:',
        second: 'Aluminium & Carbon Fiber'
    }
]

const T1Page = ({ mobileView }) => {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    const location = useLocation();

    React.useEffect(() => {
        if (location.hash === '#accessories') {
            document.getElementById("accessories").scrollIntoView({
                block: "center",
            });
        }
    }, [])

    React.useEffect(() => {
        let observerRefValue = null;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });

        if (domRef.current) {
            observer.observe(domRef.current);
            observerRefValue = domRef.current; // <-- save ref value
        }

        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue);
        }
    }, []);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Fade in={true} timeout={2000}>
                <Box
                    sx={!mobileView ? {
                        width: '100vw',
                        minHeight: "100vh",
                        backgroundImage: `url(${SmartestScooterBig1})`,
                        backgroundSize: "100vw auto",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } : {
                        width: '100vw',
                        height: "50vh",
                        backgroundImage: `url(${SmartestScooterBig1})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                >
                </Box>
            </Fade>
            <Fade in={isVisible} timeout={1000} ref={domRef}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '100vw',
                        fontSize: '100px',
                        marginRight: '10px',
                        textAlign: 'right'
                    } : {
                        color: '#000',
                        width: '100vw',
                        fontSize: '35px',
                        textAlign: 'right'
                    }}>Minimalism Un-</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '100vw',
                        fontSize: '100px',
                        writingMode: 'vertical-rl'
                    } : {
                        color: '#000',
                        width: '100vw',
                        fontSize: '35px',
                        writingMode: 'vertical-rl'
                    }}>folds</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'absolute',
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        left: '30px',
                        marginBottom: '30px',
                        fontSize: '80px',
                        letterSpacing: '10px',
                        fontWeight: 'light',
                        flex: 1
                    } : {
                        position: 'absolute',
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        left: '30px',
                        marginBottom: '30px',
                        fontSize: '20px',
                        letterSpacing: '5px',
                        fontWeight: 'light',
                        flex: 1
                    }}>The Lightest E-Scooter</MKTypography>
                    <MKTypography variant="h6" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        left: '30px',
                        top: '-200px',
                        marginBottom: '30px',
                        fontSize: '30px',
                        letterSpacing: '10px',
                        fontWeight: 'light',
                        flex: 1
                    } : {
                        position: 'relative',
                        width: '90vw',
                        color: '#000',
                        textAlign: 'left',
                        left: '30px',
                        top: '-60px',
                        marginBottom: '30px',
                        fontSize: '20px',
                        letterSpacing: '5px',
                        fontWeight: 'light',
                        flex: 1
                    }}>Flight Friendly</MKTypography>
                </Box>
            </Fade>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '100%',
                        fontSize: '80px',
                        marginLeft: '10px',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    } : {
                        color: '#000',
                        width: '100%',
                        fontSize: '40px',
                        fontWeight: 'bold',
                        textAlign: 'left'
                    }}>Bank</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '100%',
                        fontSize: '120px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                    } : {
                        color: '#000',
                        width: '100%',
                        fontSize: '50px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                    }}>Power</MKTypography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        width: '100%',
                        fontSize: '80px',
                        marginLeft: '10px',
                        fontWeight: 'light',
                        letterSpacing: '-5px',
                        textAlign: 'right'
                    } : {
                        color: '#000',
                        width: '100%',
                        fontSize: '40px',
                        fontWeight: 'light',
                        letterSpacing: '-5px',
                        textAlign: 'right',
                    }}>UltraLightWeight</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        color: '#000',
                        fontSize: '120px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-lr',
                    } : {
                        color: '#000',
                        fontSize: '50px',
                        fontWeight: 'bold',
                        writingMode: 'vertical-lr',
                    }}>Design</MKTypography>
                </Box>
            </Box>

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '70vw',
                color: '#000',
                textAlign: 'center',
                top: '-200px',
                fontSize: '30px',
                letterSpacing: '5px',
                fontWeight: 'light',
            } : {
                position: 'relative',
                width: '65vw',
                color: '#000',
                textAlign: 'center',
                top: '-100px',
                fontSize: '18px',
                letterSpacing: '3px',
                fontWeight: 'light',
            }}>We prioritized the whole experience to be seamless and convenient for the user.</MKTypography>

            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <SpecGrid specs={specs} style={!mobileView ? { width: '500px' } : { width: '80%' }} />
                <Box
                    sx={!mobileView ? {
                        minWidth: "40vw",
                        backgroundImage: `url(${SmartestScooterBig2})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } : {
                        minWidth: "90vw",
                        height: '40vh',
                        backgroundImage: `url(${SmartestScooterBig2})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            </Box>

            <Box
                sx={!mobileView ? {
                    width: '100vw',
                    minHeight: "90vh",
                    backgroundImage: `url(${SmartestScooterMac})`,
                    backgroundSize: "100vw auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                } : {
                    width: '100vw',
                    minHeight: "50vh",
                    backgroundImage: `url(${SmartestScooterMac})`,
                    backgroundSize: "100vw auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            >
            </Box>

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '70vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: '500',
                letterSpacing: '3px',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '500',
                letterSpacing: '2px',
                marginTop: '10px'
            }}>Every other folding scooter becomes a burder the moment you fold it. They are no longer a tool but a load you must carry.</MKTypography>

            <MasonryImageList itemData={itemData} />

            <Box
                sx={!mobileView ? {
                    width: '100vw',
                    minHeight: "100vh",
                    backgroundImage: `url(${SmartestScooterApp})`,
                    backgroundSize: "100vw auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                } : {
                    width: '100vw',
                    minHeight: "50vh",
                    backgroundImage: `url(${SmartestScooterApp})`,
                    backgroundSize: "100vw auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            >
            </Box>

            <Box sx={!mobileView ? { display: 'flex', justifyContent: 'center', bgcolor: '#fff', width: '90vw', height: '70vh', alignItems: 'center', alignSelf: 'center', margin: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` } : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', bgcolor: '#fff', width: '90vw', height: '70vh', alignItems: 'center', alignSelf: 'center', marginTop: '30px', marginBottom: '30px', borderRadius: '20px', overflow: 'hidden', boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}>
                <Box
                    sx={!mobileView ? {
                        minWidth: "50vw",
                        minHeight: '100%',
                        backgroundImage: `url(${Notification})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    } : {
                        minWidth: "100%",
                        minHeight: '40%',
                        backgroundImage: `url(${Notification})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
                <Box sx={!mobileView ? { width: '50%' } : { display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', height: '100%', alignItems: 'center' }}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '40px',
                        fontWeight: '800',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        width: '80%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '800',
                    }}>Forgetfulness Prevention System (FPS)</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        width: '80%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: '400',
                    }}>With the help of the Forgetfulness Prevention System (FPS), forgetting to charge your battery is a thing of the past.</MKTypography>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        position: 'relative',
                        maxWidth: '100%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '20px',
                        fontWeight: '400',
                        margin: '30px',
                    } : {
                        position: 'relative',
                        width: '80%',
                        color: '#000',
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: '400',
                    }}>Integrating calendar APIs into the application removes the need for charging from your following travel itinerary, so the application warns you to set your battery or batteries in advance.</MKTypography>
                </Box>
            </Box>

            <Divider variant="middle" sx={{ bgcolor: '#000', color: '#000', width: '100%', marginTop: '10px' }} />

            <MKTypography id="accessories" variant="h1" component="h3" sx={{
                position: 'relative',
                width: '70vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '50px',
                fontWeight: 'bold',
                fontFamily: "'M PLUS Rounded 1c', sans-serif"
            }}>Accessories</MKTypography>
            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '70vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: '500',
                letterSpacing: '3px',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '500',
                letterSpacing: '2px',
                marginTop: '30px'
            }}>Smartest Scooter helps you with many different activities thanks to the handlebar extensions.</MKTypography>

            <MasonryImageList itemData={itemData3} />

            <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                position: 'relative',
                width: '70vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '30px',
                fontWeight: '500',
                letterSpacing: '3px',
                marginTop: '30px'
            } : {
                position: 'relative',
                width: '90vw',
                color: '#000',
                textAlign: 'center',
                fontSize: '25px',
                fontWeight: '500',
                letterSpacing: '2px',
                marginTop: '30px'
            }}>Smartest scooter can be carried comfortably with its specially designed handbag.</MKTypography>

            <MasonryImageList itemData={itemData2} />

            <Box
                sx={{
                    width: '100vw',
                    minHeight: "70vh",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <MKTypography variant="h1" component="h3" sx={{
                    position: 'relative',
                    width: '80vw',
                    color: '#000',
                    textAlign: 'center',
                    fontSize: '25px',
                    letterSpacing: '3px',
                    fontWeight: '700',
                    marginTop: '50px',
                    fontFamily: "'M PLUS Rounded 1c', sans-serif"
                }}>With its unparaleld design, lightness, and smart features, the smartest scooter is not only a vehicle you will use for transportation but its a companion to integrate into your day.</MKTypography>
            </Box>
            <MasonryImageList itemData={itemData4} />
            <MKBox>
                <Footer content={footerRoutes} />
            </MKBox>
        </Box>
    )
}

export default T1Page

// <Box
//                 sx={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'flex-start',
//                     flexDirection: 'row',
//                     width: '100vw'
//                 }}
//             >
//                 <Box sx={{ flex: 1 }}>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '60px',
//                         marginRight: '10px',
//                         textAlign: 'right',
//                         transform: 'rotate(170deg)',
//                         marginLeft: '10px'
//                     }}>Your</MKTypography>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '60px',
//                         writingMode: 'vertical-lr'
//                     }}>Power</MKTypography>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '60px',
//                         textAlign: 'left',
//                         marginLeft: '10px'
//                     }}>Station</MKTypography>
//                 </Box>
//                 <Box sx={{ flex: 1 }}>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '100px',
//                         marginRight: '10px',
//                         textAlign: 'right'
//                     }}>Flight</MKTypography>
//                     <MKTypography variant="h1" component="h3" sx={{
//                         color: '#fff',
//                         width: '100%',
//                         fontSize: '100px',
//                         writingMode: 'vertical-rl'
//                     }}>Friendly</MKTypography>
//                 </Box>
//             </Box>