import * as React from 'react';
import Box from '@mui/material/Box';

import Carousel from '../../components/carousel/carousel';
// import BuyRentButton from '../../components/buyRentButton/buyRentButton';
// import SpecGrid from '../../components/specGrid/specGrid';

import breakpoints from "assets/theme/base/breakpoints";

//images
import SmartestHelmetBig1 from '../../assets/images/SmartestHelmetConcept/1.png'
import SmartestHelmetBig2 from '../../assets/images/SmartestHelmetConcept/2.png'
import SmartestHelmetBig3 from '../../assets/images/SmartestHelmetConcept/3.png'

import SmartestHelmetSmall1 from '../../assets/images/SmartestHelmetConcept/5.png'
import SmartestHelmetSmall2 from '../../assets/images/SmartestHelmetConcept/21.png'

import SmartestHelmetSmall3 from '../../assets/images/SmartestHelmetConcept/9.png'
import SmartestHelmetSmall4 from '../../assets/images/SmartestHelmetConcept/19.png'
import SmartestHelmetSmall5 from '../../assets/images/SmartestHelmetConcept/15.png'
import SmartestHelmetSmall8 from '../../assets/images/SmartestHelmetConcept/20.png'
import SmartestHelmetSmall10 from '../../assets/images/SmartestHelmetConcept/23.png'

import SmartestHelmetCamera from '../../assets/images/SmartestHelmetConcept/18.png'
import SmartestHelmetLed from '../../assets/images/SmartestHelmetConcept/6.png'
import MKTypography from 'MKcomponents/MKTypography';
import WishlistForm from 'components/wishlistForm/wishlistForm';

import CircularProgress from '@mui/material/CircularProgress';
import { joinHelmetWishlist } from '../../firebase/firebase';
import BasicModal from 'components/basicModal/basicModal';

import { useNavigate } from "react-router-dom";


const helmet_images = [
    {
        imgPath: SmartestHelmetBig1,
    },
    {
        imgPath: SmartestHelmetBig2,
    },
    {
        imgPath: SmartestHelmetBig3,
    },
    {
        imgPath: SmartestHelmetSmall1,
    },
    {
        imgPath: SmartestHelmetSmall2,
    },
    {
        imgPath: SmartestHelmetSmall3,
    },
    {
        imgPath: SmartestHelmetSmall4,
    },
    {
        imgPath: SmartestHelmetSmall5,
    },
    {
        imgPath: SmartestHelmetSmall8,
    },
    {
        imgPath: SmartestHelmetSmall10,
    },
    {
        imgPath: SmartestHelmetCamera,
    },
    {
        imgPath: SmartestHelmetLed,
    }
];

const WishlistPage = () => {
    const [mobileView, setMobileView] = React.useState(false);

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false)

    React.useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    const onClickHandler = (firstName, surname, email, selectedCountry) => {
        setLoading(true)

        joinHelmetWishlist(firstName, surname, email, selectedCountry).then((data) => {
            setLoading(false)
            if (data) {
                setSuccess(true)
            }
        })
    }

    return (
        <Box sx={{ bgcolor: '#f3f3f3', width: '100vw', minHeight: '100vh' }}>
            <Box mt='10px' sx={!mobileView ? { display: 'flex', width: '100vw', flexDirection: 'row', justifyContent: 'space-around' } : { display: 'flex', width: '100vw', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Carousel images={helmet_images} />
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                        <MKTypography variant="h1" component="h3" sx={{
                            width: '90%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '20px',
                            fontWeight: '500',
                            marginTop: '30px'
                        }}>Features</MKTypography>
                        <MKTypography variant="h1" component="h3" sx={{
                            width: '90%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '15px',
                            fontWeight: 'light',
                            marginTop: '30px',
                            marginBottom: '30px'
                        }}>
                            <li>Danger Prediction & Warning System</li>
                            <br />
                            <li>Communication with Other Users</li>
                            <br />
                            <li>Heads-Up Display</li>
                            <br />
                            <li>Ultra-Bright Rear LED</li>
                            <br />
                            <li>Emergency Call</li>
                            <br />
                            <li>Action Camera Recording</li>
                            <br />
                            <li>GPS Helmet Tracker</li>
                            <br />
                            <li>Voice Asistant</li>
                            <br />
                            <li>Over the Air Updates</li>
                        </MKTypography>
                        <MKTypography variant="h1" component="h3" sx={{
                            width: '90%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '20px',
                            fontWeight: '500',
                            marginTop: '20px'
                        }}>Why Smartest Helmet?</MKTypography>
                        <MKTypography variant="h1" component="h3" sx={{
                            width: '90%',
                            color: '#000',
                            textAlign: 'left',
                            fontSize: '15px',
                            fontWeight: 'light',
                            marginTop: '30px',
                            marginBottom: '30px'
                        }}>
                            <li>Motorcycles are the deadliest transportation vehicles by far and unfortunately not a single protective gear for motorcycle riders reduces the likelihood of an accident.</li>
                            <br />
                            <li>More than 380,000 motorcycle riders die each year, and millions get injured.</li>
                            <br />
                            <li>Smartest Helmet will actively protect the rider, not just after an accident.</li>
                            <br />
                            <li>Smartest Helmet will reduce motorcycle accidents through computer vision and AI.</li>
                        </MKTypography>

                        {loading ? <CircularProgress /> : <WishlistForm onClick={(firstName, surname, email, selectedCountry) => onClickHandler(firstName, surname, email, selectedCountry, "Smartest Scooter")} />}
                        {success ? <BasicModal open={success} handleClose={() => {
                            setSuccess(false)
                            navigate("/");
                        }} /> : null}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default WishlistPage









































































// import * as React from 'react';
// import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
// import { useTheme } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';

// import Carousel from '../../components/carousel/carousel';
// import BuyRentButton from '../../components/buyRentButton/buyRentButton';
// import SpecGrid from '../../components/specGrid/specGrid';

// import breakpoints from "assets/theme/base/breakpoints";

// //images
// //scooter
// import SmartestScooterBig1 from '../../assets/images/SmartestScooter/SmartestScooterBig1.png'
// import SmartestScooterBig2 from '../../assets/images/SmartestScooter/SmartestScooterBig2.png'
// import SmartestScooterBig3 from '../../assets/images/SmartestScooter/SmartestScooterBig3.png'

// import SmartestScooterExtensionBig1 from '../../assets/images/SmartestScooter/SmartestScooterExtensionBig1.png'
// import SmartestScooterExtensionBig2 from '../../assets/images/SmartestScooter/SmartestScooterExtensionBig2.png'
// import SmartestScooterExtensionBig3 from '../../assets/images/SmartestScooter/SmartestScooterExtensionBig3.png'

// import SmartestScooterExtensionSmall1 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall1.png'
// import SmartestScooterExtensionSmall2 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall2.png'
// import SmartestScooterExtensionSmall3 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall3.png'
// import SmartestScooterExtensionSmall4 from '../../assets/images/SmartestScooter/SmartestScooterExtensionSmall4.png'

// import SmartestScooterSmall1 from '../../assets/images/SmartestScooter/SmartestScooterSmall1.png'
// import SmartestScooterSmall2 from '../../assets/images/SmartestScooter/SmartestScooterSmall2.png'
// import SmartestScooterSmall3 from '../../assets/images/SmartestScooter/SmartestScooterSmall3.png'
// import SmartestScooterSmall4 from '../../assets/images/SmartestScooter/SmartestScooterSmall4.png'
// //sd1
// import SD1Big1 from '../../assets/images/SD1-2/SD1Big1.jpeg'
// import SD1Big2 from '../../assets/images/SD1-2/SD1Big2.jpg'
// import SD1Big3 from '../../assets/images/SD1-2/SD1Big3.jpeg'

// import SD1Small3 from '../../assets/images/SD1-2/SD1Small3.jpeg'
// import SD1Small4 from '../../assets/images/SD1-2/SD1Small4.jpg'
// //cyber
// import CyberScooterSmall1 from '../../assets/images/Cyberscooter/CyberScooterSmall1.jpeg'
// import CyberScooterSmall2 from '../../assets/images/Cyberscooter/CyberScooterSmall2.jpeg'

// const scooter_images = [
//     {
//         imgPath: SmartestScooterBig1,
//     },
//     {
//         imgPath: SmartestScooterBig2,
//     },
//     {
//         imgPath: SmartestScooterBig3,
//     },
//     {
//         imgPath: SmartestScooterSmall1,
//     },
//     {
//         imgPath: SmartestScooterSmall2,
//     },
//     {
//         imgPath: SmartestScooterSmall3,
//     },
//     {
//         imgPath: SmartestScooterSmall4,
//     },
//     {
//         imgPath: SmartestScooterExtensionBig1,
//     },
//     {
//         imgPath: SmartestScooterExtensionBig2,
//     },
//     {
//         imgPath: SmartestScooterExtensionBig3,
//     },
//     {
//         imgPath: SmartestScooterExtensionSmall1,
//     },
//     {
//         imgPath: SmartestScooterExtensionSmall2,
//     },
//     {
//         imgPath: SmartestScooterExtensionSmall3,
//     },
//     {
//         imgPath: SmartestScooterExtensionSmall4,
//     },
// ];

// const sd1_images = [
//     {
//         imgPath: SD1Big1,
//     },
//     {
//         imgPath: SD1Big2,
//     },
//     {
//         imgPath: SD1Big3,
//     },
//     {
//         imgPath: SD1Small3,
//     },
//     {
//         imgPath: SD1Small4,
//     },
// ];

// const cyber_images = [
//     {
//         imgPath: CyberScooterSmall1,
//     },
//     {
//         imgPath: CyberScooterSmall2,
//     },
// ];

// const scooter_specs = [
//     {
//         first: 'Weight:',
//         second: '5 kg'
//     },
//     {
//         first: 'Speed:',
//         second: '25 km/h'
//     },
//     {
//         first: 'Range:',
//         second: '25 km'
//     },
//     {
//         first: 'Motor Power:',
//         second: '2 x 150 W'
//     },
// ]

// const sd1_specs = [
//     {
//         first: 'Range:',
//         second: '150 km'
//     },
//     {
//         first: 'Torque:',
//         second: '200 Nm'
//     },
//     {
//         first: 'Weight:',
//         second: '18 kg'
//     },
//     {
//         first: 'Motor Speed:',
//         second: '25 km/h'
//     },
// ]

// const cyber_specs = [
//     {
//         first: 'Range:',
//         second: '100 km'
//     },
//     {
//         first: 'Weight:',
//         second: '19 kg'
//     },
//     {
//         first: 'Speed:',
//         second: '32 km/h'
//     },
//     {
//         first: 'Motor Power:',
//         second: '2 x 350 W'
//     },
//     {
//         first: 'Max Payload:',
//         second: '200 kg'
//     },
// ]

// const scooter_specs2 = [
//     {
//         first: 'Weight:',
//         second: '3 kg'
//     },
//     {
//         first: 'Speed:',
//         second: '25 km/h'
//     },
//     {
//         first: 'Range:',
//         second: '12 km'
//     },
//     {
//         first: 'Motor Power:',
//         second: '2 x 150 W'
//     },
// ]

// const sd1_specs2 = [
//     {
//         first: 'Range:',
//         second: '300 km'
//     },
//     {
//         first: 'Torque:',
//         second: '200 Nm'
//     },
//     {
//         first: 'Weight:',
//         second: '22 kg'
//     },
//     {
//         first: 'Motor Speed:',
//         second: '25 km/h'
//     },
// ]

// const cyber_specs2 = [
//     {
//         first: 'Range:',
//         second: '200 km'
//     },
//     {
//         first: 'Weight:',
//         second: '20 kg'
//     },
//     {
//         first: 'Speed:',
//         second: '32 km/h'
//     },
//     {
//         first: 'Motor Power:',
//         second: '2 x 350 W'
//     },
//     {
//         first: 'Max Payload:',
//         second: '200 kg'
//     },
// ]


// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`full-width-tabpanel-${index}`}
//             aria-labelledby={`full-width-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 0 }}>
//                     {children}
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }

// const WishlistPage = () => {
//     const theme = useTheme();
//     const [value, setValue] = React.useState(0);

//     const [specScoot, setSpecScoot] = React.useState([]);
//     const [specCyber, setSpecCyber] = React.useState([]);
//     const [specSd, setSpecSd] = React.useState([]);

//     const [mobileView, setMobileView] = React.useState(false);

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//         setSpecScoot(scooter_specs)
//         setSpecCyber(cyber_specs)
//         setSpecSd(sd1_specs)
//     };

//     const handleChangeIndex = (index) => {
//         setValue(index);
//     };

//     React.useEffect(() => {
//         // A function that sets the display state for the DefaultNavbarMobile.
//         function displayMobileNavbar() {
//             if (window.innerWidth < breakpoints.values.lg) {
//                 setMobileView(true);
//             } else {
//                 setMobileView(false);
//             }
//         }

//         window.addEventListener("resize", displayMobileNavbar);
//         displayMobileNavbar();

//         return () => window.removeEventListener("resize", displayMobileNavbar);
//     }, []);

//     React.useEffect(() => {
//         setSpecScoot(scooter_specs)
//         setSpecCyber(cyber_specs)
//         setSpecSd(sd1_specs)
//     }, [])

//     const onChangeHandler = (label) => {
//         switch (label) {
//             case 'base':
//                 setSpecScoot(scooter_specs)
//                 break;
//             case 'baseCyber':
//                 setSpecCyber(cyber_specs)
//                 break;
//             case 'baseSd1':
//                 setSpecSd(sd1_specs)
//                 break;
//             case 'air':
//                 setSpecScoot(scooter_specs2)
//                 break;
//             case 'longSd1':
//                 setSpecSd(sd1_specs2)
//                 break;
//             case 'longCyber':
//                 setSpecCyber(cyber_specs2)
//                 break;
//             default:
//                 break;
//         }
//     }

//     return (
//         <Box sx={{ bgcolor: 'transparent', width: '100vw' }}>
//             <AppBar position="static">
//                 <Tabs
//                     value={value}
//                     onChange={handleChange}
//                     indicatorColor="secondary"
//                     textColor="inherit"
//                     variant="fullWidth"
//                     aria-label="full width tabs example"
//                     sx={{ margin: '10px', zIndex: 5 }}
//                 >
//                     <Tab label="Smartest Scooter" {...a11yProps(0)} />
//                     <Tab label="SD1" {...a11yProps(1)} />
//                     <Tab label="CyberScooter" {...a11yProps(2)} />
//                 </Tabs>
//             </AppBar>
//             <SwipeableViews
//                 axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//                 index={value}
//                 onChangeIndex={handleChangeIndex}
//             >
//                 <TabPanel value={value} index={0} dir={theme.direction}>
//                     <Box mt='10px' sx={!mobileView ? { display: 'flex', width: '100vw', flexDirection: 'row', justifyContent: 'space-around' } : { display: 'flex', width: '100vw', flexDirection: 'column', justifyContent: 'space-around' }}>
//                         <Carousel images={scooter_images} />
//                         <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
//                             <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
//                                 <SpecGrid specs={specScoot} style={{ width: '90%', marginTop: '10px', }} />
//                                 <BuyRentButton scooter={true} changeSpecs={(lab) => onChangeHandler(lab)} />
//                             </Box>
//                         </Box>
//                     </Box>
//                 </TabPanel>
//                 <TabPanel value={value} index={1} dir={theme.direction}>
//                     <Box mt='10px' sx={!mobileView ? { display: 'flex', width: '100vw', flexDirection: 'row', justifyContent: 'space-around' } : { display: 'flex', width: '100vw', flexDirection: 'column', justifyContent: 'space-around' }}>
//                         <Carousel images={sd1_images} />
//                         <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
//                             <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
//                                 <SpecGrid specs={specSd} removable={false} style={{ width: '90%', marginTop: '10px', }} />
//                                 <BuyRentButton sd={true} changeSpecs={(lab) => onChangeHandler(lab)} />
//                             </Box>
//                         </Box>
//                     </Box>
//                 </TabPanel>
//                 <TabPanel value={value} index={2} dir={theme.direction}>
//                     <Box mt='10px' sx={!mobileView ? { display: 'flex', width: '100vw', flexDirection: 'row', justifyContent: 'space-around' } : { display: 'flex', width: '100vw', flexDirection: 'column', justifyContent: 'space-around' }}>
//                         <Carousel images={cyber_images} />
//                         <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
//                             <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
//                                 <SpecGrid specs={specCyber} removable={false} style={{ width: '90%', marginTop: '70px', }} />
//                                 <BuyRentButton cyber={true} changeSpecs={(lab) => onChangeHandler(lab)} />
//                             </Box>
//                         </Box>
//                     </Box>
//                 </TabPanel>
//             </SwipeableViews>
//         </Box>
//     );
// }

// export default WishlistPage


// <TabPanel value={value} index={3} dir={theme.direction}>
// <Box mt='10px' sx={{ display: 'flex', width: '100vw', flexDirection: 'row', justifyContent: 'space-around' }}>
//     <Carousel images={scooter_images} />
//     <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
//         <Box sx={{ display: 'flex', width: '40vw', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
//             <SpecGrid specs={scooter_specs} style={{ width: '90%', marginTop: '10px', }} />
//             <BuyRentButton />
//         </Box>
//     </Box>
// </Box>
// </TabPanel>