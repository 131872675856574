import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import MKTypography from 'MKcomponents/MKTypography';
import { Breadcrumbs } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Item = styled(Paper)(({ theme, present }) => ({
    backgroundColor: `${present ? '#ffffff11' : '#00000022'}`,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#000',
    backdropFilter: `blur(5px)`,
    borderRadius: '10px',
    boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 5px`,
}));

export default function BasicStack({ mobileView }) {
    return (
        <Box sx={{ width: '90%' }}>
            <Stack spacing={1}>
                <Item>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>Proof of concept</MKTypography>
                </Item>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '40px' }} />
                </Box>
                <Item>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>First danger predictions & warnings on video.</MKTypography>
                </Item>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '40px' }} />
                </Box>
                <Item present={true}>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>Data gathering helmet design & manufacturing</MKTypography>
                </Item>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '40px' }} />
                </Box>
                <Item>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>
                        Gather Data
                    </MKTypography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '20px' }} />
                    </Box>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>
                        Label & Annotate Data
                    </MKTypography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '20px' }} />
                    </Box>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>
                        Train & Improve the Algorithm
                    </MKTypography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '20px' }} />
                    </Box>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>
                        Repeat
                    </MKTypography>
                </Item>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '40px' }} />
                </Box>
                <Item>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>Prototype in Q4/2023</MKTypography>
                </Item>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider orientation="vertical" flexItem sx={{ bgcolor: '#000', color: '#000', height: '40px' }} />
                </Box>
                <Item>
                    <MKTypography variant="h1" component="h3" sx={!mobileView ? {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: '400',
                    } : {
                        width: '100%',
                        color: '#000',
                        textAlign: 'center',
                        fontWeight: '400',
                        fontSize: '15px',
                        letterSpacing: '1px',
                    }}>Kickstarter and Indiegogo launch in 2024</MKTypography>
                </Item>

            </Stack>
        </Box>
    );
}