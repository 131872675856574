/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "MKcomponents/MKBox";
import MKTypography from "MKcomponents/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "components/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/efe.png";
import team2 from "assets/images/samuel.png";

import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import BrushRoundedIcon from '@mui/icons-material/BrushRounded';

const socials1 = [
  {
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/in/efe-poyraz/",
  },
  {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/pyzefepyz/?next=%2F",
  },
  {
    icon: <BrushRoundedIcon />,
    link: "https://www.behance.net/efepoyraz/projects",
  },
  {
    icon: <EmailRoundedIcon />,
    link: "mailto:efe@smartestcompany.co",
  },
]

const socials2 = [
  {
    icon: <LinkedInIcon />,
    link: "https://www.linkedin.com/in/samuel-karatas-501963218/",
  },
  {
    icon: <TwitterIcon />,
    link: "https://twitter.com/samuelkaratas",
  },
  {
    icon: <InstagramIcon />,
    link: "https://instagram.com/samuelkaratas",
  },
  {
    icon: <GitHubIcon />,
    link: "https://github.com/samuelkaratas",
  },
  {
    icon: <EmailRoundedIcon />,
    link: "mailto:samuel@smartestcompany.co",
  },
]


function Team() {
  return (
    <MKBox
      component="section"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="black" sx={{ textAlign: 'left' }}>
              Founders
            </MKTypography>
            <MKTypography variant="body2" color="black" opacity={0.8} sx={{ textAlign: 'left' }}>
              We have a highly enthusiastic team believing the mission of solving daily problems by adding value to the user’s life through cutting-edge technologies and making it look incredible at the same time.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Efe Poyraz"
                position={{ color: "secondary", label: "CDO | cofounder" }}
                description="Efe Poyraz has worked on many transportation solutions and got ideathon and design awards thanks to his experience in the field of design for 5 years. He studied in Innovation & Management master program and worked on more than 15 projects. He brought his mechanic and innovative design motto and management experience to Smartest Company."
                socials={socials1}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Samuel Karatas"
                position={{ color: "secondary", label: "CTO | cofounder" }}
                description="Samuel Karatas has worked on many projects that one of which was a drone taxi company for the past year, responsible for autonomous flight artificial intelligence. He brought his experience combining computer vision and deep learning techniques and the mentality of the prototyping development cycle to Smartest Company."
                socials={socials2}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
