// @mui icons

import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Material Kit 2 React components
import MKTypography from "../../MKcomponents/MKTypography";

// Images
import logo from "../../assets/images/logo.png"

const date = new Date().getFullYear();

export default {
    brand: {
        name: "Smartest Co.",
        image: `url(${logo})`,
        route: "/",
    },
    socials: [
        {
            icon: <LinkedInIcon />,
            link: "https://www.linkedin.com/company/smartestcompany/",
        },
        {
            icon: <TwitterIcon />,
            link: "https://twitter.com/smartestco",
        },
        {
            icon: <YouTubeIcon />,
            link: "https://www.youtube.com/@smartestco",
        },
        {
            icon: <InstagramIcon />,
            link: "https://www.instagram.com/smartestco/",
        },
    ],
    menus: [
        {
            name: "Company",
            items: [
                { name: "Smartest Helmet", route: "/smartesthelmet" },
                { name: "Smartest Lab", route: "/smartestlab" },
                { name: "Join Wishlist", route: "/wishlist" },
            ],
        },
        {
            name: "Contact",
            items: [
                { name: "samuel@smartestcompany.co", href: "mailto:samuel@smartestcompany.co" },
                { name: "efe@smartestcompany.co", href: "mailto:efe@smartestcompany.co" },
            ],
        },
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="bold" color='white'>
            All rights reserved. Copyright &copy; {date} Smartest Company.
        </MKTypography>
    ),
};

/*
<MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        color='black'
        variant="button"
        fontWeight="regular"
      >
        Smartest Company
      </MKTypography>
      .




      { name: "SD1", route: "/sd1" },


        {
            name: "For Investors",
            items: [
                { name: "Download Pitch Deck", href: "/src/assets/dummy_data/PYZ-Pitch deck.pdf", download: true },
            ],
        },


      */